import styled from "styled-components";
import { BackArrow } from "../SVG/BackArrow";
import pxIntoRem from "../../utils/pxIntoRem";

const HeaderBlock = styled.header`
  display: flex;
  align-items: center;
  padding: ${pxIntoRem(18)} ${pxIntoRem(14)};
  height: ${pxIntoRem(86)};
  box-sizing: border-box;
  border-top: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  border-radius: ${pxIntoRem(0)} ${pxIntoRem(0)} ${pxIntoRem(12)} ${pxIntoRem(12)};
  background: rgb(255, 255, 255);
`;

const HeaderBack = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  border-radius: ${pxIntoRem(7)};
  width: ${pxIntoRem(50)};
  height: ${pxIntoRem(50)};
  flex-shrink: 0;
  background-color: transparent;
  cursor: pointer;
`;

const HeaderBackIcon = styled(BackArrow)`
  width: ${pxIntoRem(18)};
`;

interface IHeaderContent {
  $centered: boolean;
}

const HeaderContent = styled.div<IHeaderContent>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $centered }) => ($centered ? "center" : "flex-start")};
  flex-grow: 1;
`;

const HeaderTitle = styled.h1`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(18)};
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
`;

const HeaderSubtitle = styled.p`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(3)};
`;

const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pxIntoRem(7)};
  background: rgb(244, 244, 244);
  width: ${pxIntoRem(50)};
  height: ${pxIntoRem(50)};
  flex-shrink: 0;

  svg {
    width: ${pxIntoRem(28)};
    height: ${pxIntoRem(28)};
  }
`;

export { HeaderBack, HeaderBackIcon, HeaderBlock, HeaderContent, HeaderTitle, HeaderSubtitle, HeaderIcon };
