import { FC, useEffect, useState } from "react";
import {
  NavigationBlock,
  NavigationButton,
  NavigationButtonDot,
  NavigationButtonIcon,
  NavigationButtonText,
} from "./NavigationStyles";
import { useLocation, useNavigate } from "react-router";
import { NavigationHome } from "../SVG/NavigationHome";
import { NavigationFilter } from "../SVG/NavigationFilter";
import { NavigationHeart } from "../SVG/NavigationHeart";
import { NavigationDiscont } from "../SVG/NavigationDiscount";
import { useModal } from "../../hooks/useModal";
import { Filter } from "../Modals/Filter/Filter";
import { IDealer } from "../../types/dealer";
import { DealersService } from "../../services/dealersService";

interface INavigation {
  isFilter?: boolean;
  onFilterChange?: () => void;
}

const Navigation: FC<INavigation> = ({ isFilter, onFilterChange }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [dealers, setDealers] = useState<IDealer[]>([]);
  const [filterOpen, openFilter, closeFilter] = useModal();

  const changeFilterOpen = filterOpen ? closeFilter : openFilter;

  useEffect(() => {
    const getData = async () => {
      const getDealers = async () => {
        const dealers = await DealersService.getDealers();
        setDealers(dealers);
      };

      await Promise.allSettled([getDealers()]);
    };

    getData();
  }, []);

  return (
    <NavigationBlock $filter={!!isFilter}>
      <Filter isOpen={filterOpen} onClose={closeFilter} onChange={onFilterChange} dealers={dealers} />

      <NavigationButton
        onClick={() => navigate("/")}
        $active={
          !filterOpen &&
          (location.pathname == "/" ||
            location.pathname.includes("/brands") ||
            location.pathname == "/car" ||
            location.pathname == "/cars")
        }
      >
        <NavigationButtonDot />

        <NavigationButtonIcon>
          <NavigationHome />
        </NavigationButtonIcon>

        <NavigationButtonText>Главная</NavigationButtonText>
      </NavigationButton>

      {isFilter && (
        <NavigationButton onClick={changeFilterOpen} $active={filterOpen}>
          <NavigationButtonDot />

          <NavigationButtonIcon>
            <NavigationFilter />
          </NavigationButtonIcon>

          <NavigationButtonText>Фильтр</NavigationButtonText>
        </NavigationButton>
      )}

      <NavigationButton
        onClick={() => navigate("/favourites")}
        $active={!filterOpen && location.pathname == "/favourites"}
      >
        <NavigationButtonDot />

        <NavigationButtonIcon>
          <NavigationHeart />
        </NavigationButtonIcon>

        <NavigationButtonText>Избранное</NavigationButtonText>
      </NavigationButton>

      <NavigationButton
        onClick={() => navigate("/suggestion")}
        $active={!filterOpen && location.pathname == "/suggestion"}
      >
        <NavigationButtonDot />

        <NavigationButtonIcon>
          <NavigationDiscont />
        </NavigationButtonIcon>

        <NavigationButtonText>Акции</NavigationButtonText>
      </NavigationButton>
    </NavigationBlock>
  );
};

export default Navigation;
