import { FC, SVGProps } from "react";

interface INavigationDot extends SVGProps<SVGSVGElement> {}

const NavigationDot: FC<INavigationDot> = ({ ...props }) => {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="4" cy="1" r="4" fill="#B90B04" />
    </svg>
  );
};

export { NavigationDot };
