import { FC } from "react";
import { LoaderBlock, LoaderCircle, LoaderImage, LoaderImageBlock } from "./LoaderStyles";
import Portal from "../../Portal/Portal";

interface ILoader {
  isOpen: boolean;
}

const Loader: FC<ILoader> = ({ isOpen }) => {
  return (
    <Portal>
      <LoaderBlock $open={isOpen}>
        <LoaderImageBlock>
          <LoaderImage alt="keys" src="/images/preloader.svg" />

          {/* <LoaderCircle $delay={-1000} /> */}
          <LoaderCircle $delay={0} />
          <LoaderCircle $delay={2000} />
          <LoaderCircle $delay={4000} />
          <LoaderCircle $delay={-2000} />
          <LoaderCircle $delay={-4000} />
          <LoaderCircle $delay={-6000} />

          {/* <LoaderCircle $delay={1000} />
          <LoaderCircle $delay={2000} />
          <LoaderCircle $delay={4000} /> */}
        </LoaderImageBlock>
      </LoaderBlock>
    </Portal>
  );
};

export { Loader };
