import { FC, SVGProps } from "react";

interface INavigationHeart extends SVGProps<SVGSVGElement> {}

const NavigationHeart: FC<INavigationHeart> = ({ ...props }) => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M12.0001 21.0833C17.2928 21.0833 21.5834 16.7926 21.5834 11.5C21.5834 6.20723 17.2928 1.91663 12.0001 1.91663C6.70735 1.91663 2.41675 6.20723 2.41675 11.5C2.41675 16.7926 6.70735 21.0833 12.0001 21.0833Z"
        fill="#1E1E1E"
      />
      <path
        d="M12.3159 16.2915C12.1434 16.349 11.8464 16.349 11.6739 16.2915C10.1789 15.7836 6.82471 13.6465 6.82471 10.024C6.82471 8.42362 8.10887 7.12988 9.69971 7.12988C10.6389 7.12988 11.4726 7.58029 11.9997 8.28946C12.5172 7.58987 13.3605 7.12988 14.2997 7.12988C15.8905 7.12988 17.1747 8.42362 17.1747 10.024C17.1747 13.6465 13.8205 15.7836 12.3159 16.2915Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { NavigationHeart };
