import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const BrandBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(244, 244, 244);
  padding-bottom: ${pxIntoRem(72)};
`;

const BrandBrands = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxIntoRem(14)};
  padding: ${pxIntoRem(14)};
`;

const Brand = styled.button`
  position: relative;
  width: 100%;
  height: ${pxIntoRem(120)};
  cursor: pointer;
  border-radius: ${pxIntoRem(12)};
  background: rgb(255, 255, 255);
  padding: ${pxIntoRem(30)};
  cursor: pointer;
`;

const BrandImage = styled.img`
  width: ${pxIntoRem(131)};
  height: auto;
  max-width: 100%;
`;

const BrandAdv = styled.div`
  display: flex;
  height: ${pxIntoRem(169)};
  width: 100%;
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
`;

const BrandAdvImage = styled.img`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  object-fit: cover;
  border-radius: ${pxIntoRem(12)};
  background-color: rgb(255, 255, 255);
  cursor: pointer;
`;

export { BrandBlock, BrandBrands, Brand, BrandImage, BrandAdv, BrandAdvImage };
