import { FC, SVGProps } from "react";

interface IClose extends SVGProps<SVGSVGElement> {}

const Close: FC<IClose> = ({ ...props }) => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.05939 4.0001L7.35939 1.70011C7.64939 1.41011 7.64939 0.930107 7.35939 0.640107C7.06939 0.350107 6.5894 0.350107 6.2994 0.640107L3.99939 2.9401L1.69936 0.640107C1.40936 0.350107 0.929365 0.350107 0.639375 0.640107C0.349375 0.930107 0.349375 1.41011 0.639375 1.70011L2.93939 4.0001L0.639375 6.3001C0.349375 6.5901 0.349375 7.0701 0.639375 7.3601C0.789375 7.5101 0.979364 7.5801 1.16936 7.5801C1.35936 7.5801 1.54936 7.5101 1.69936 7.3601L3.99939 5.0601L6.2994 7.3601C6.4494 7.5101 6.63939 7.5801 6.82939 7.5801C7.01939 7.5801 7.20939 7.5101 7.35939 7.3601C7.64939 7.0701 7.64939 6.5901 7.35939 6.3001L5.05939 4.0001Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { Close };
