import React, { FC } from "react";
import {
  CarBlock,
  CarContent,
  CarDiscount,
  CarDiscountIcon,
  CarFavourite,
  CarImage,
  CarInfo,
  CarInfoIcon,
  CarInfoItem,
  CarInfoItemContent,
  CarInfoTitle,
  CarInfoValue,
  CarLine,
  CarPrice,
  CarPriceCredit,
  CarPriceCreditTitle,
  CarPriceCreditValue,
  CarPriceValue,
  CarText,
  CarTitle,
} from "./CarStyles";
import { Heart } from "../SVG/Heart";
import { Engine } from "../SVG/Engine";
import { Drive } from "../SVG/Drive";
import { GasPump } from "../SVG/GasPump";
import { Transmission } from "../SVG/Transmission";
import { useNavigate } from "react-router";
import { CarsService } from "../../services/carsService";
import { ICar } from "../../types/car";
import Formatter from "../../utils/formatter";

interface ICarElement {
  isDiscount?: boolean;
  car: ICar;
  onChange: () => any;
}

const Car: FC<ICarElement> = ({ isDiscount, car, onChange }) => {
  const navigate = useNavigate();

  const addToFavourites = async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    await CarsService.addToFavourites(car.vin);
    onChange();
  };

  const removeFromFavorites = async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    await CarsService.removeFromFavorites(car.vin);
    onChange();
  };

  const toCar = () =>
    navigate(`/car/${car.vin}`, {
      state: {
        car,
      },
    });

  return (
    <CarBlock onClick={toCar}>
      {isDiscount && <CarDiscountIcon alt="discount" src="/images/discount.svg" />}

      <CarFavourite onClick={!!car.is_favorite ? removeFromFavorites : addToFavourites} $favourite={!!car.is_favorite}>
        <Heart />
      </CarFavourite>

      <CarImage src={car.images[0] ?? ""} alt="car" />

      <CarContent>
        <CarTitle>
          {car.mark_name} <span>{car.model_name}</span>
        </CarTitle>

        <CarText>
          {car.engine_power} л.с. / <span>{car.year}</span>
        </CarText>

        <CarInfo>
          <CarInfoItem>
            <CarInfoIcon>
              <Transmission />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Коробка</CarInfoTitle>
              <CarInfoValue>{car.transmission}</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>

          <CarInfoItem>
            <CarInfoIcon>
              <GasPump />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Тип двигателя</CarInfoTitle>
              <CarInfoValue>{car.engine_type}</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>

          <CarInfoItem>
            <CarInfoIcon>
              <Engine />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Объём двигателя</CarInfoTitle>
              <CarInfoValue>{car.engine_volume} л.</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>

          <CarInfoItem>
            <CarInfoIcon>
              <Drive />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Привод</CarInfoTitle>
              <CarInfoValue>{car.drive_type}</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>
        </CarInfo>

        <CarLine />

        {!!car.old_price && <CarDiscount>{Formatter.formatPrice(car.old_price)}₽</CarDiscount>}

        <CarPrice>
          <CarPriceValue>{Formatter.formatPrice(car?.price ?? "")}₽</CarPriceValue>

          {!!car.loan && (
            <CarPriceCredit>
              <CarPriceCreditTitle>В кредит</CarPriceCreditTitle>
              <CarPriceCreditValue>от {Formatter.formatPrice(car.loan)} ₽/мес</CarPriceCreditValue>
            </CarPriceCredit>
          )}
        </CarPrice>
      </CarContent>
    </CarBlock>
  );
};

export { Car };
