import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const CarsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(244, 244, 244);
  padding-bottom: ${pxIntoRem(72)};
`;

const CarsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(14)};
  padding: ${pxIntoRem(14)};
`;

export { CarsBlock, CarsList };
