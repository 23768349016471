import { FC, SVGProps } from "react";

interface ICrown extends SVGProps<SVGSVGElement> {}

const Crown: FC<ICrown> = ({ ...props }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M16.7702 19.48H7.23024C6.81024 19.48 6.43024 19.21 6.29024 18.82L2.13024 7.17004C1.80024 6.24004 2.86024 5.45004 3.65024 6.02004L7.65024 8.88004C8.18024 9.26004 8.94024 9.03004 9.17024 8.42004L11.0602 3.38004C11.3802 2.51004 12.6102 2.51004 12.9302 3.38004L14.8202 8.42004C15.0502 9.04004 15.8002 9.26004 16.3402 8.88004L20.3402 6.02004C21.1402 5.45004 22.1902 6.25004 21.8602 7.17004L17.7002 18.82C17.5702 19.21 17.1902 19.48 16.7702 19.48Z"
        fill="#B90B04"
      />
      <path
        d="M17 22.5H7C6.59 22.5 6.25 22.16 6.25 21.75C6.25 21.34 6.59 21 7 21H17C17.41 21 17.75 21.34 17.75 21.75C17.75 22.16 17.41 22.5 17 22.5Z"
        fill="#B90B04"
      />
      <path
        d="M14.5 15.25H9.5C9.09 15.25 8.75 14.91 8.75 14.5C8.75 14.09 9.09 13.75 9.5 13.75H14.5C14.91 13.75 15.25 14.09 15.25 14.5C15.25 14.91 14.91 15.25 14.5 15.25Z"
        fill="#B90B04"
      />
    </svg>
  );
};

export { Crown };
