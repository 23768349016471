import { FC, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import WebApp from "../../utils/telegram/webapp";
import {
  HeaderBack,
  HeaderBackIcon,
  HeaderBlock,
  HeaderContent,
  HeaderIcon,
  HeaderSubtitle,
  HeaderTitle,
} from "./HeaderStyles";

interface IHeader {
  onBack?: () => void;
  title: string;
  subtitle: string;
  icon: JSX.Element;
}

const Header: FC<IHeader> = ({ onBack, title, subtitle, icon }) => {
  const navigate = useNavigate();

  return (
    <HeaderBlock>
      {onBack && (
        <HeaderBack onClick={() => navigate(-1)}>
          <HeaderBackIcon />
        </HeaderBack>
      )}

      <HeaderContent $centered={!!onBack}>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubtitle>{subtitle}</HeaderSubtitle>
      </HeaderContent>

      <HeaderIcon>{icon}</HeaderIcon>
    </HeaderBlock>
  );
};

export default Header;
