// import React, { FC, useMemo, useRef, useState } from "react";
// import {
//   FilterRangeBlock,
//   FilterRangeHeader,
//   FilterRangeHeaderArrow,
//   FilterRangeHeaderTitle,
// } from "./FilterRangeStyles";
// import { DownArrow } from "../../../../SVG/DownArrow";
// import { useModal } from "../../../../../hooks/useModal";

// interface IFilterRange {
//   title: string;
//   values: {
//     title: string;
//   }[];
// }

// const FilterRange: FC<IFilterRange> = ({ title, values }) => {

//   return (
// <FilterRangeBlock onClick={changeOpen}>
//   <FilterRangeHeader>
//     <FilterRangeHeaderTitle>{title}</FilterRangeHeaderTitle>

//     <FilterRangeHeaderArrow>
//       <DownArrow />
//     </FilterRangeHeaderArrow>
//   </FilterRangeHeader>

//       <FilterRangeItems $height={height}>
//         <FilterRangeItem>
//           <FilterRangeItemCheckbox />

//           <FilterRangeItemText>РОЛЬФ</FilterRangeItemText>

//           <FilterRangeItemSpecial>Специальные условия</FilterRangeItemSpecial>
//         </FilterRangeItem>

//         <FilterRangeItem>
//           <FilterRangeItemCheckbox />

//           <FilterRangeItemText>Название дилера</FilterRangeItemText>
//         </FilterRangeItem>
//       </FilterRangeItems>
//     </FilterRangeBlock>
//   );
// };

// export { FilterRange };

import { FC, useEffect, useMemo, useState } from "react";
import { useModal } from "../../../../../hooks/useModal";
import { DownArrow } from "../../../../SVG/DownArrow";
import {
  FilterRangeBlock,
  FilterRangeContent,
  FilterRangeHeader,
  FilterRangeHeaderArrow,
  FilterRangeHeaderTitle,
  FilterRangeInputBody,
  FilterRangeInputColumn,
  FilterRangeInputColumnInput,
  FilterRangeInputColumnSplitter,
  FilterRangeInputRange,
  FilterRangeInputRangeInput,
  FilterRangeInputRangeInputs,
  FilterRangeInputRangeProgress,
} from "./FilterRangeStyles";

interface IFilterProps {
  title: string;
  minValue: number;
  maxValue: number;
  needFormat?: boolean;
  min: number;
  max: number;
  gap?: number;
  step: number;
  numberFields: boolean;
  onChange(min: number, max: number): any;
}

const FilterRange: FC<IFilterProps> = ({
  title,
  min,
  max,
  minValue,
  maxValue,
  needFormat,
  onChange,
  step,
  numberFields,
  gap = 5,
}) => {
  const [isOpen, openSelect, closeSelect] = useModal();
  const changeOpen = isOpen ? closeSelect : openSelect;

  const height = useMemo(() => {
    return isOpen ? 74 : 0;
  }, [isOpen]);

  const changeMin = (intValue: number) => onChange(intValue, max);
  const changeMax = (intValue: number) => onChange(min, intValue);

  function onMinInput(value: string) {
    const floatRegexp = /\d+\./;
    const intValue = deformatCost(value.replace(/\D/g, "") || "0"); //  Number.parseFloat(value || "0"); //  needFormat ? deformatCost(value || "0") : Number.parseFloat(value || "0");

    // if (intValue < minValue) {
    //   return;
    // }
    if (typeof intValue === "number" || floatRegexp.test(value)) {
      changeMin(intValue);
    }
  }

  function onMaxInput(value: string) {
    const floatRegexp = /\d+\./;
    const intValue = deformatCost(value.replace(/\D/g, "") || "0"); //  needFormat ? deformatCost(value || "0") : Number.parseFloat(value || "0");

    // if (intValue > maxValue) {
    //   return;
    // }

    if (typeof intValue === "number" || floatRegexp.test(value)) {
      changeMax(intValue);
    }
  }

  function onMinRange(value: string) {
    const intValue = Number.parseFloat(value);

    if (intValue >= maxValue || intValue <= minValue) {
      return;
    }

    if (intValue >= max) {
      changeMin(max - gap);
      return;
    }

    changeMin(intValue);
  }

  function onMaxRange(value: string) {
    const intValue = Number.parseFloat(value);

    if (intValue >= maxValue || intValue <= minValue) {
      return;
    }

    if (intValue <= min) {
      changeMax(min + gap);

      return;
    }

    changeMax(intValue);
  }

  function onMinOut(value: string) {
    const intValue = deformatCost(value.replace(/\D/g, "") || "0"); // needFormat ? deformatCost(value || "0") : Number.parseFloat(value);

    if (intValue > max || intValue < minValue || intValue > maxValue) {
      changeMin(minValue);
    }
  }

  function onMaxOut(value: string) {
    const intValue = deformatCost(value.replace(/\D/g, "") || "0"); // needFormat ? deformatCost(value || "0") : Number.parseFloat(value || "0");
    if (intValue < min || intValue < minValue || intValue > maxValue) {
      changeMax(maxValue);
    }
  }

  const costProgressStyleObj = useMemo(() => {
    return getStyles(min, max, minValue, maxValue);
  }, [min, max, minValue, maxValue]);

  function getStyles(min: number, max: number, minVal: number, maxVal: number) {
    const maxNum = maxVal - minVal;

    let right = 100 - ((max - minVal) / maxNum) * 100;
    let left = ((min - minVal) / maxNum) * 100;
    let width = 100 - right - left;

    if (width > 100) {
      width = 0;
    }

    if (left > 100) {
      left = 0;
      width = 0;
    }

    if (right > 100) {
      right = 0;
      width = 0;
    }

    return {
      right: right + "%",
      left: left + "%",
      width: width + "%",
    };
  }

  const reverseString = (str: string) => str.split("").reverse().join("");

  function formatCost(cost: number) {
    const str = reverseString(String(cost));
    const resultArr = [];
    for (let i = 0; i < str.length; i += 3) {
      const substring = reverseString(str.slice(i, i + 3));
      resultArr.unshift(substring);
    }
    return resultArr.join(" ");
  }

  function deformatCost(cost: string): number {
    return Number(cost.split(" ").join(""));
  }

  return (
    <FilterRangeBlock onClick={changeOpen}>
      <FilterRangeHeader>
        <FilterRangeHeaderTitle>{title}</FilterRangeHeaderTitle>

        <FilterRangeHeaderArrow $open={isOpen}>
          <DownArrow />
        </FilterRangeHeaderArrow>
      </FilterRangeHeader>

      <FilterRangeContent $height={height} onClick={(e) => e.stopPropagation()}>
        <FilterRangeInputBody>
          <FilterRangeInputColumn>
            <FilterRangeInputColumnInput
              value={"от  " + (Number.isInteger(min) ? formatCost(min) : min)}
              onChange={(e) => onMinInput(e.currentTarget.value)}
              onBlur={(e) => onMinOut(e.currentTarget.value)}
              type={numberFields ? "number" : "text"}
            />
          </FilterRangeInputColumn>

          <FilterRangeInputColumnSplitter />

          <FilterRangeInputColumn>
            <FilterRangeInputColumnInput
              // value={needFormat ? formatCost(max) : max}
              value={"до  " + (Number.isInteger(max) ? formatCost(max) : max)}
              onChange={(e) => onMaxInput(e.currentTarget.value)}
              onBlur={(e) => onMaxOut(e.currentTarget.value)}
              type={numberFields ? "number" : "text"}
            />
          </FilterRangeInputColumn>

          <FilterRangeInputRange>
            <FilterRangeInputRangeProgress style={costProgressStyleObj} />
          </FilterRangeInputRange>

          <FilterRangeInputRangeInputs>
            <FilterRangeInputRangeInput
              type={"range"}
              min={minValue}
              max={maxValue}
              value={min}
              onChange={(e) => onMinRange(e.currentTarget.value)}
              step={step}
            />

            <FilterRangeInputRangeInput
              type={"range"}
              min={minValue}
              max={maxValue}
              value={max}
              onChange={(e) => onMaxRange(e.currentTarget.value)}
              step={step}
            />
          </FilterRangeInputRangeInputs>
        </FilterRangeInputBody>
      </FilterRangeContent>
    </FilterRangeBlock>
  );
};

export { FilterRange };
