import appConfig from "../configs/appConfig";

export interface ICarsFilter {
  brand_id: number;
  brand_name: string;
  region_name: string;
  dealer_id?: number[];
  model_name?: string;
  year?: number;
  body_type?: string[];
  transmission?: string[];
  engine_volume?: string;
  engine_volume_max?: string;
  engine_volume_min?: string;
  engine_type?: string[];
  drive_type?: string;
  body_color?: string;
  price_min?: number;
  price_max?: number;
}

interface ICarBook {
  dealer: string;
  first_name: string;
  last_name: string;
  phone: string;
}

interface IGetEngineVolume {
  min_price: number;
  max_price: number;
}

interface IGetPrices {
  min_engine_volume: number;
  max_engine_volume: number;
}

interface IGetCarData {
  body_types: string[];
  transmissions: string[];
  engine_types: string[];
}

class CarsService {
  static clearFilter = () => {
    localStorage.setItem("filter", "{}");
  };

  static changeFilter = (filter?: Partial<ICarsFilter>) => {
    const localStorageFilter = JSON.parse(localStorage.getItem("filter") ?? "{}");

    for (let key in filter) {
      localStorageFilter[key] = (filter as any)[key];
    }

    localStorage.setItem("filter", JSON.stringify(localStorageFilter));
  };

  static getFilter = (): ICarsFilter => {
    const localStorageFilter = JSON.parse(localStorage.getItem("filter") ?? "{}");

    return localStorageFilter;
  };

  static getPriceRanges = async (): Promise<IGetPrices> => {
    const res = await fetch(appConfig.apiUrl + `/cars/prices`, {
      method: "GET",
    });

    const json = await res.json();
    return json;
  };

  static getEngineVolumeRanges = async (): Promise<IGetEngineVolume> => {
    const res = await fetch(appConfig.apiUrl + `/cars/engine-volume-range`, {
      method: "GET",
    });

    const json = await res.json();
    return json;
  };

  static getCarData = async (): Promise<IGetCarData> => {
    const res = await fetch(appConfig.apiUrl + `/car-data`, {
      method: "GET",
    });

    const json = await res.json();
    return json;
  };

  static getDefaultFilters = async () => {
    const [price, engineVolume, carData] = await Promise.all([
      CarsService.getPriceRanges(),
      CarsService.getEngineVolumeRanges(),
      CarsService.getCarData(),
    ]);

    return {
      ...price,
      ...engineVolume,
      ...carData,
    };
  };

  static getCars = async () => {
    const filter = JSON.parse(localStorage.getItem("filter") ?? "{}");

    delete filter["brand_name"];
    delete filter["region_name"];

    const res = await fetch(appConfig.apiUrl + `/cars/filter`, {
      method: "POST",
      body: JSON.stringify({
        ...filter,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    return json;
  };

  static getCar = async (vin: string) => {
    const res = await fetch(appConfig.apiUrl + `/cars/car/${vin}`, {
      method: "GET",
    });

    const json = await res.json();
    return json;
  };

  static bookCar = async (book: ICarBook) => {
    const res = await fetch(appConfig.apiUrl + `/cars/book`, {
      body: JSON.stringify({
        ...book,
      }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    return json;
  };

  static getFavourites = async () => {
    const telegramId = window.Telegram.WebApp?.initDataUnsafe?.user?.id ?? 1;

    const res = await fetch(appConfig.apiUrl + `/cars/favorites?telegram_id=${telegramId}`);
    const json = await res.json();
    return json?.favorites ?? [];
  };

  static getDeals = async () => {
    const res = await fetch(appConfig.apiUrl + `/cars/deals`);
    const json = await res.json();
    return json;
  };

  static addToFavourites = async (vin: string) => {
    const telegramId = window.Telegram.WebApp?.initDataUnsafe?.user?.id ?? 1;

    const res = await fetch(appConfig.apiUrl + `/cars/add-to-favorites`, {
      body: JSON.stringify({
        vin,
        telegram_id: telegramId,
      }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    return json;
  };

  static removeFromFavorites = async (vin: string) => {
    const telegramId = window.Telegram.WebApp?.initDataUnsafe?.user?.id ?? 1;

    const res = await fetch(appConfig.apiUrl + `/cars/remove-from-favorites`, {
      method: "DELETE",
      body: JSON.stringify({
        vin,
        telegram_id: telegramId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();
    return json;
  };
}

export { CarsService };
