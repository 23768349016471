import { FC, SVGProps } from "react";

interface ICopy extends SVGProps<SVGSVGElement> {}

const Copy: FC<ICopy> = ({ ...props }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_174_7814)">
        <path
          d="M2.25 7.875V3H1.5C0.671627 3 0 3.67163 0 4.5V10.5C0 11.3284 0.671627 12 1.5 12H7.5C8.32837 12 9 11.3284 9 10.5V9.75H4.125C3.29663 9.75 2.25 8.70337 2.25 7.875ZM10.5 0H4.5C3.67163 0 3 0.671627 3 1.5V7.5C3 8.32837 3.67163 9 4.5 9H10.5C11.3284 9 12 8.32837 12 7.5V1.5C12 0.671627 11.3284 0 10.5 0Z"
          fill="#565656"
        />
      </g>
      <defs>
        <clipPath id="clip0_174_7814">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { Copy };
