import { FC, useEffect, useMemo, useState } from "react";
import {
  ContactBlock,
  ContactButton,
  ContactContent,
  ContactHeader,
  ContactHeaderClose,
  ContactHeaderCloseIcon,
  ContactHeaderCloseText,
  ContactHeaderTitle,
  ContactInput,
  ContactInputs,
} from "./ContactStyles";
import { Close } from "../../SVG/Close";
import Navigation from "../../Navigation/Navigation";
import { ContactSelect } from "./components/ContactSelect/ContactSelect";
import { useNavigate } from "react-router";
import { IDealer } from "../../../types/dealer";
import usePhoneInput from "../../../hooks/usePhoneInput";
import { CarsService } from "../../../services/carsService";
import Portal from "../../Portal/Portal";

interface IContact {
  isOpen: boolean;
  dealers: IDealer[];
  onClose(): void;
}

const Contact: FC<IContact> = ({ dealers, isOpen, onClose }) => {
  const [dealer, setDealer] = useState<string | null>(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const { phone, notFormattedPhone, onPhoneChange, onPhoneKeyDown } = usePhoneInput();

  const error = useMemo(() => {
    return !dealer || !name || !surname || !notFormattedPhone;
  }, [dealer, name, surname, notFormattedPhone]);

  const sendContact = async () => {
    if (error) return;

    await CarsService.bookCar({
      dealer: dealer as string,
      first_name: name,
      last_name: surname,
      phone: notFormattedPhone,
    });

    onClose();
  };

  return (
    <Portal>
      <ContactBlock $open={isOpen} onClick={onClose}>
        <ContactContent onClick={(e) => e.stopPropagation()}>
          <ContactHeader>
            <ContactHeaderTitle>Связаться с менеджером</ContactHeaderTitle>

            <ContactHeaderClose onClick={onClose}>
              <ContactHeaderCloseText>Закрыть</ContactHeaderCloseText>
              <ContactHeaderCloseIcon>
                <Close />
              </ContactHeaderCloseIcon>
            </ContactHeaderClose>
          </ContactHeader>

          <ContactInputs>
            <ContactSelect
              placeholder="Выберите дилера"
              value={dealer}
              values={dealers.map(({ name }) => name)}
              onChange={setDealer}
            />

            <ContactInput placeholder="Ваше имя*" value={name} onChange={(e) => setName(e.currentTarget.value)} />

            <ContactInput placeholder="Фамилия*" value={surname} onChange={(e) => setSurname(e.currentTarget.value)} />

            <ContactInput
              placeholder="+7 (___) ___-__-__"
              inputMode="numeric"
              value={phone}
              onChange={onPhoneChange}
              onKeyDown={onPhoneKeyDown}
            />
          </ContactInputs>

          <ContactButton disabled={error} onClick={sendContact}>
            Отправить
          </ContactButton>
        </ContactContent>

        <Navigation isFilter />
      </ContactBlock>
    </Portal>
  );
};

export { Contact };
