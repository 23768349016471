import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { IndexBlock, IndexRegion, IndexRegionImage, IndexRegions, IndexRegionTitle } from "./IndexStyles";
import { Region } from "../../components/SVG/Region";
import { IRegion } from "../../types/region";
import Navigation from "../../components/Navigation/Navigation";
import { useNavigate } from "react-router";
import { RegionsService } from "../../services/regionsService";
import Preloader from "../../components/Modals/Preloader/Preloader";
import { Loader } from "../../components/Modals/Loader/Loader";
import { CarsService } from "../../services/carsService";

const Index = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState<IRegion[]>([]);

  const getRegions = async () => {
    const regions = await RegionsService.getRegions();
    setRegions(regions);

    setIsLoading(false);
  };

  useEffect(() => {
    getRegions();
  }, []);

  const toRegion = (region: IRegion) => {
    CarsService.changeFilter({
      region_name: region.name,
    });

    navigate(`/brands/${region.id}`);
  };

  return (
    <IndexBlock>
      <Loader isOpen={isLoading} />

      <Header title="Выберите регион" subtitle="для доступных предложений" icon={<Region />} />

      <IndexRegions>
        {regions.map((region) => {
          return (
            <IndexRegion onClick={() => toRegion(region)} key={region.id}>
              <IndexRegionTitle>{region.name}</IndexRegionTitle>
              <IndexRegionImage alt="region" src={region.cover_image} />
            </IndexRegion>
          );
        })}
      </IndexRegions>

      <Navigation />
    </IndexBlock>
  );
};

export { Index };
