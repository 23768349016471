import appConfig from "../configs/appConfig";
import { IRegion } from "../types/region";

class RegionsService {
  static getRegions = async (): Promise<IRegion[]> => {
    const res = await fetch(appConfig.apiUrl + "/regions");

    const json = await res.json();

    return json;
  };
}

export { RegionsService };
