import appConfig from "../configs/appConfig";
import { IBrandsBanner } from "../types/banners";

class BannersService {
  static getBrandsBanners = async (regionId: number): Promise<IBrandsBanner[]> => {
    const res = await fetch(appConfig.apiUrl + `/banners?region_id=${regionId}`);

    const json = await res.json();

    return json;
  };
}

export { BannersService };
