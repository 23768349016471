import styled from "styled-components";
import { NavigationDot } from "../SVG/NavigationDot";
import pxIntoRem from "../../utils/pxIntoRem";

interface INavigationBlock {
  $filter: boolean;
}

const NavigationBlock = styled.footer<INavigationBlock>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999999;
  display: grid;
  grid-template-columns: ${({ $filter }) =>
    $filter
      ? `${pxIntoRem(97)} ${pxIntoRem(100)} ${pxIntoRem(100)} ${pxIntoRem(97)}`
      : `${pxIntoRem(97)} ${pxIntoRem(100)} ${pxIntoRem(97)}`};
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  border-radius: ${pxIntoRem(16)} ${pxIntoRem(16)} ${pxIntoRem(0)} ${pxIntoRem(0)};
  background: rgb(255, 255, 255);
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)} ${pxIntoRem(14)} ${pxIntoRem(14)};
`;

const NavigationButtonDot = styled(NavigationDot)`
  position: absolute;
  top: ${pxIntoRem(0)};
  width: ${pxIntoRem(8)};
`;

const NavigationButtonIcon = styled.div`
  width: ${pxIntoRem(23)};
  height: ${pxIntoRem(23)};

  svg {
    width: 100%;
    height: 100%;
  }
`;

interface INavigationButton {
  $active: boolean;
}

const NavigationButton = styled.button<INavigationButton>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pxIntoRem(14)} ${pxIntoRem(14)} ${pxIntoRem(0)} ${pxIntoRem(14)};
  background-color: transparent;
  cursor: pointer;

  ${NavigationButtonIcon} path {
    fill: ${({ $active }) => ($active ? "rgb(185, 11, 4)" : "#1E1E1E")};
  }

  ${NavigationButtonDot} {
    display: ${({ $active }) => ($active ? "block" : "none")};
  }
`;

const NavigationButtonText = styled.span`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(10)};
  font-weight: 600;
  line-height: ${pxIntoRem(12)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(7)};
`;

export { NavigationBlock, NavigationButton, NavigationButtonText, NavigationButtonDot, NavigationButtonIcon };
