import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { CarPriceCreditValue } from "../../components/Car/CarStyles";
import { Contact } from "../../components/Modals/Contact/Contact";
import Navigation from "../../components/Navigation/Navigation";
import { Drive } from "../../components/SVG/Drive";
import { Engine } from "../../components/SVG/Engine";
import { GasPump } from "../../components/SVG/GasPump";
import { Heart } from "../../components/SVG/Heart";
import { Transmission } from "../../components/SVG/Transmission";
import useDraggableScroll from "../../hooks/useDraggableScroll";
import { useModal } from "../../hooks/useModal";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { CarsService } from "../../services/carsService";
import { ICar } from "../../types/car";
import Formatter from "../../utils/formatter";
import {
  CarBack,
  CarBackIcon,
  CarBackText,
  CarBlock,
  CarButton,
  CarColors,
  CarColorsItem,
  CarColorsItems,
  CarColorsTitle,
  CarContent,
  CarDealers,
  CarDealersList,
  CarDealersText,
  CarDealersTitle,
  CarHeader,
  CarHeaderContent,
  CarHeaderFavourite,
  CarHeaderText,
  CarHeaderTitle,
  CarInfo,
  CarInfoIcon,
  CarInfoItem,
  CarInfoItemContent,
  CarInfoTitle,
  CarInfoValue,
  CarLine,
  CarLogo,
  CarPrice,
  CarPriceCredit,
  CarPriceCreditAlarm,
  CarPriceCreditAlarmIcon,
  CarPriceCreditAlarmNotification,
  CarPriceCreditAlarmNotificationImage,
  CarPriceCreditAlarmText,
  CarPriceText,
  CarProperties,
  CarPropertiesHeader,
  CarPropertiesHeaderItem,
  CarPropertiesText,
} from "./CarStyles";
import { CarDealer } from "./components/CarDealer/CarDealer";
import { CarMap } from "./components/CarMap/CarMap";
import CarSlider from "./components/CarSlider/CarSlider";

const Car = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [creditOpen, openCredit, closeCredit] = useModal();
  const changeCredit = creditOpen ? closeCredit : openCredit;

  const creditRef = useOutsideClick(closeCredit);

  const { sliderRef } = useDraggableScroll();

  const [car, setCar] = useState<ICar | null>(null);

  const getData = async () => {
    const car = location.state?.car;

    if (!car) {
      const vin = params.vin ?? null;

      if (!vin) {
        navigate(-1);
        return;
      }

      const car = await CarsService.getCar(vin);
      setCar(car);
      return;
    }

    setCar(car);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const [contactOpen, openContact, closeContact] = useModal();

  const back = () => navigate(-1);

  const addToFavourites = async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!car) return;

    await CarsService.addToFavourites(car?.vin);

    setCar(
      (prev) =>
        ({
          ...prev,
          is_favorite: 1,
        } as any)
    );
  };

  const removeFromFavorites = async (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!car) return;

    await CarsService.removeFromFavorites(car.vin);

    setCar(
      (prev) =>
        ({
          ...prev,
          is_favorite: 0,
        } as any)
    );
  };

  const [carDescriptionType, setCarDescriptionType] = useState<"standard_equipment" | "technical_specifications">(
    "standard_equipment"
  );

  return (
    <CarBlock>
      <Contact isOpen={contactOpen} dealers={car?.dealers ?? []} onClose={closeContact} />

      <CarLogo alt="logo" src={car?.brandLogo} />
      <CarBack onClick={back}>
        <CarBackIcon />
        <CarBackText>Назад</CarBackText>
      </CarBack>

      <CarSlider images={car?.images ?? []} />

      <CarContent>
        <CarHeader>
          <CarHeaderContent>
            <CarHeaderTitle>
              {car?.mark_name} <span>{car?.model_name}</span>
            </CarHeaderTitle>

            <CarHeaderText>
              {car?.engine_power} л.с. / <span>{car?.year}</span>
            </CarHeaderText>
          </CarHeaderContent>

          <CarHeaderFavourite
            onClick={!!car?.is_favorite ? removeFromFavorites : addToFavourites}
            $favourite={!!car?.is_favorite}
          >
            <Heart />
          </CarHeaderFavourite>
        </CarHeader>

        <CarInfo>
          <CarInfoItem>
            <CarInfoIcon>
              <Transmission />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Коробка</CarInfoTitle>
              <CarInfoValue>{car?.transmission}</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>

          <CarInfoItem>
            <CarInfoIcon>
              <GasPump />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Тип двигателя</CarInfoTitle>
              <CarInfoValue>{car?.engine_type}</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>

          <CarInfoItem>
            <CarInfoIcon>
              <Engine />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Объём двигателя</CarInfoTitle>
              <CarInfoValue>{car?.engine_volume} л.</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>

          <CarInfoItem>
            <CarInfoIcon>
              <Drive />
            </CarInfoIcon>

            <CarInfoItemContent>
              <CarInfoTitle>Привод</CarInfoTitle>
              <CarInfoValue>{car?.drive_type}</CarInfoValue>
            </CarInfoItemContent>
          </CarInfoItem>
        </CarInfo>

        {car?.colors && (
          <CarColors>
            <CarColorsTitle>Доступные цвета</CarColorsTitle>

            <CarColorsItems>
              {car?.colors.map((color) => {
                return <CarColorsItem $color={color} key={color} />;
              })}
            </CarColorsItems>
          </CarColors>
        )}

        <CarLine />

        <CarPrice>
          <CarPriceText>{Formatter.formatPrice(car?.price ?? "")}₽</CarPriceText>

          <CarPriceCredit>
            <CarPriceCreditAlarm $open={creditOpen} onClick={changeCredit} ref={creditRef}>
              <CarPriceCreditAlarmNotification>
                Информация по расчету и цене автомобиля является ориентировочной, предоставляется для справки и не
                является офертой. Точную информацию по расчетам цены и кредита можно получить у специалиста компании.
                <CarPriceCreditAlarmNotificationImage alt="arrow" src="/images/arrow-black.png" />
              </CarPriceCreditAlarmNotification>

              <CarPriceCreditAlarmIcon />
              <CarPriceCreditAlarmText>В кредит</CarPriceCreditAlarmText>
            </CarPriceCreditAlarm>

            {car?.loan && <CarPriceCreditValue>от {Formatter.formatPrice(car.loan)}₽/мес</CarPriceCreditValue>}
          </CarPriceCredit>
        </CarPrice>

        <CarButton onClick={openContact}>Забронировать просмотр</CarButton>

        {car && car[carDescriptionType] && (
          <CarProperties>
            <CarPropertiesHeader ref={sliderRef}>
              {car?.standard_equipment && (
                <CarPropertiesHeaderItem
                  $active={carDescriptionType === "standard_equipment"}
                  onClick={() => setCarDescriptionType("standard_equipment")}
                >
                  Стандартное оборудование
                </CarPropertiesHeaderItem>
              )}

              {car?.technical_specifications && (
                <CarPropertiesHeaderItem
                  $active={carDescriptionType === "technical_specifications"}
                  onClick={() => setCarDescriptionType("technical_specifications")}
                >
                  Технические характеристики
                </CarPropertiesHeaderItem>
              )}
            </CarPropertiesHeader>

            <CarPropertiesText dangerouslySetInnerHTML={{ __html: car[carDescriptionType] ?? "" }}>
              {/* <h2>Система автоматической парковки</h2>
    <p>
      Безопасно и полностью автоматически припаркует ваш автомобиль даже в самых сложных ситуациях! Система
      самостоятельно осуществляет перпендикулярную и параллельную парковку посредством управления рулём, газом и
      тормозом.
    </p>

    <h2>Система кругового обзора 540°</h2>
    <p>
      На дисплей нового TXL выводится видео в высоком разрешении с панорамным обзором, а функция «прозрачное
      шасси» позволяет выводить на экран пространство под капотом и то, что скрыто от глаз водителя, чтоб вам
      было легко объезжать препятствия 
    </p> */}
            </CarPropertiesText>
          </CarProperties>
        )}

        <CarDealers>
          <CarDealersTitle>Автомобиль представлен в автосалоне</CarDealersTitle>
          <CarDealersText>Вы можете выбрать удобный</CarDealersText>

          <CarDealersList>
            {(car?.dealers ?? []).map((dealer) => {
              return (
                <React.Fragment key={dealer.id}>
                  <CarDealer dealer={dealer} onBook={openContact} />
                  <CarMap coordinates={dealer.coordinates} />
                </React.Fragment>
              );
            })}
          </CarDealersList>
        </CarDealers>
      </CarContent>

      <Navigation isFilter />
    </CarBlock>
  );
};

export { Car };
