import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const IndexBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(244, 244, 244);
  padding-bottom: ${pxIntoRem(72)};
`;

const IndexRegions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(14)};
  padding: ${pxIntoRem(14)};
`;

const IndexRegion = styled.button`
  position: relative;
  width: 100%;
  height: ${pxIntoRem(278)};
  border-radius: ${pxIntoRem(12)};
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
`;

const IndexRegionImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const IndexRegionTitle = styled.span`
  position: absolute;
  left: ${pxIntoRem(14)};
  bottom: ${pxIntoRem(14)};
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0%;
  text-align: left;
  padding: ${pxIntoRem(4)} ${pxIntoRem(8)};
  height: ${pxIntoRem(24)};
  border-radius: ${pxIntoRem(100)};
  background-color: rgb(255, 255, 255);
  z-index: 999;
`;

export { IndexBlock, IndexRegion, IndexRegionImage, IndexRegionTitle, IndexRegions };
