import Slider from "react-slick";
import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const CarSliderBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #b90b04;
  position: relative;
`;

const CarSliderBody = styled(Swiper)`
  width: 100%;
  position: relative;
  height: ${pxIntoRem(300)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarSliderSlide = styled(SwiperSlide)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${pxIntoRem(300)};
`;

const CarSliderSlideImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${pxIntoRem(300)};
  object-fit: cover;
`;

const CarSliderPagination = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxIntoRem(5)};
  position: absolute;
  bottom: ${pxIntoRem(49.5)};
  z-index: 9999;
`;

interface ICarSliderPaginationButton {
  $active?: boolean;
}

const CarSliderPaginationButton = styled.div<ICarSliderPaginationButton>`
  width: ${pxIntoRem(30)};
  height: ${pxIntoRem(4)};
  border-radius: ${pxIntoRem(100)};
  background-color: ${({ $active }) => ($active ? "rgb(185, 11, 4)" : "rgb(255, 255, 255, 0.2)")};
  cursor: pointer;
`;

const CarSliderIndex = styled.span`
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  right: ${pxIntoRem(16)};
  bottom: ${pxIntoRem(42)};
  position: absolute;
  z-index: 999;
`;

export {
  CarSliderBlock,
  CarSliderSlide,
  CarSliderBody,
  CarSliderSlideImage,
  CarSliderPagination,
  CarSliderPaginationButton,
  CarSliderIndex,
};
