import { FC, SVGProps } from "react";

interface INavigationFilter extends SVGProps<SVGSVGElement> {}

const NavigationFilter: FC<INavigationFilter> = ({ ...props }) => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M20.2412 3.92924V6.03757C20.2412 6.80424 19.762 7.76257 19.2829 8.24174L15.162 11.8834C14.587 12.3626 14.2037 13.3209 14.2037 14.0876V18.2084C14.2037 18.7834 13.8204 19.5501 13.3412 19.8376L11.9995 20.7001C10.7537 21.4667 9.02871 20.6042 9.02871 19.0709V13.9917C9.02871 13.3209 8.64538 12.4584 8.26204 11.9792L7.30371 10.973L12.8812 2.01257H18.3245C19.3787 2.01257 20.2412 2.87507 20.2412 3.92924Z"
        fill="#1E1E1E"
      />
      <path
        d="M11.3296 2.01257L6.36546 9.97632L4.62129 8.14591C4.14212 7.66674 3.75879 6.80424 3.75879 6.22924V4.02507C3.75879 2.87507 4.62129 2.01257 5.67546 2.01257H11.3296Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { NavigationFilter };
