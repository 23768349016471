import { FC, SVGProps } from "react";

interface INavigationHome extends SVGProps<SVGSVGElement> {}

const NavigationHome: FC<INavigationHome> = ({ ...props }) => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M19.7051 6.53576L14.1851 2.67368C12.6805 1.61951 10.3709 1.67701 8.92389 2.79826L4.12264 6.54535C3.16431 7.29285 2.40723 8.82618 2.40723 10.0336V16.6461C2.40723 19.0899 4.39098 21.0832 6.83473 21.0832H17.1655C19.6093 21.0832 21.593 19.0995 21.593 16.6557V10.1582C21.593 8.86451 20.7593 7.27368 19.7051 6.53576Z"
        fill="#1E1E1E"
      />
      <path
        d="M12 17.9688C11.6071 17.9688 11.2812 17.6429 11.2812 17.25V14.375C11.2812 13.9821 11.6071 13.6562 12 13.6562C12.3929 13.6562 12.7188 13.9821 12.7188 14.375V17.25C12.7188 17.6429 12.3929 17.9688 12 17.9688Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { NavigationHome };
