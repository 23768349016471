import { FC, SVGProps } from "react";

interface ITransmission extends SVGProps<SVGSVGElement> {}

const Transmission: FC<ITransmission> = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12 2C10.4812 2 9.25 3.23122 9.25 4.75C9.25 6.00878 10.0957 7.07002 11.25 7.39648V11.25C11.25 11.6642 11.5858 12 12 12C12.4142 12 12.75 11.6642 12.75 11.25V7.39648C13.9043 7.07002 14.75 6.00878 14.75 4.75C14.75 3.23122 13.5188 2 12 2ZM5.5 4C4.11929 4 3 5.11929 3 6.5V18.5C3 19.8807 4.11929 21 5.5 21C6.88071 21 8 19.8807 8 18.5V15H9.5V18.5C9.5 19.8807 10.6193 21 12 21C13.3807 21 14.5 19.8807 14.5 18.5V15H19C20.1046 15 21 14.1046 21 13V6.5C21 5.11929 19.8807 4 18.5 4C17.1193 4 16 5.11929 16 6.5V10H13.75V11.5H17.5V6.5C17.5 5.94772 17.9477 5.5 18.5 5.5C19.0523 5.5 19.5 5.94772 19.5 6.5V13C19.5 13.2761 19.2761 13.5 19 13.5H13V18.5C13 19.0523 12.5523 19.5 12 19.5C11.4477 19.5 11 19.0523 11 18.5V13.5H6.5V18.5C6.5 19.0523 6.05228 19.5 5.5 19.5C4.94772 19.5 4.5 19.0523 4.5 18.5V6.5C4.5 5.94772 4.94772 5.5 5.5 5.5C6.05228 5.5 6.5 5.94772 6.5 6.5V11.5H10.25V10H8V6.5C8 5.11929 6.88071 4 5.5 4Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { Transmission };
