import styled from "styled-components";
import { BackArrow } from "../../components/SVG/BackArrow";
import { Alarm } from "../../components/SVG/Alarm";
import { DownArrow } from "../../components/SVG/DownArrow";
import { Crown } from "../../components/SVG/Crown";
import pxIntoRem from "../../utils/pxIntoRem";

const CarBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${pxIntoRem(72)};
`;

const CarLogo = styled.img`
  max-width: ${pxIntoRem(133)};
  object-fit: cover;
  position: absolute;
  right: ${pxIntoRem(14)};
  top: ${pxIntoRem(14)};
  z-index: 999;
`;

const CarBack = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${pxIntoRem(13)};
  top: ${pxIntoRem(15)};
  cursor: pointer;
  background-color: transparent;
  z-index: 99999;
`;

const CarBackIcon = styled(BackArrow)`
  width: ${pxIntoRem(18)};

  path {
    fill: rgb(255, 255, 255);
  }
`;

const CarBackText = styled.span`
  margin-left: ${pxIntoRem(10)};
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
`;

const CarSlider = styled.img`
  height: ${pxIntoRem(300)};
  width: 100%;
  object-fit: cover;
`;

const CarContent = styled.div`
  position: relative;
  // border-radius: ${pxIntoRem(12)} ${pxIntoRem(12)} ${pxIntoRem(0)} ${pxIntoRem(0)};
  background: rgb(255, 255, 255);
  padding-top: ${pxIntoRem(6)};

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: -${pxIntoRem(19)};
    border-radius: ${pxIntoRem(12)} ${pxIntoRem(12)} ${pxIntoRem(0)} ${pxIntoRem(0)};
    background: rgb(255, 255, 255);
    height: ${pxIntoRem(20)};
    width: 100%;
    z-index: 9999;
  }
`;

const CarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
`;

const CarHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ICarFavourite {
  $favourite: boolean;
}

const CarHeaderFavourite = styled.button<ICarFavourite>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(33.33)};
  height: ${pxIntoRem(33.33)};
  background: ${({ $favourite }) => ($favourite ? "rgba(185, 11, 4, 0.4)" : "rgb(30, 30, 30, 0.4)")};
  margin-left: auto;
  border-radius: 100%;
  position: relative;
  right: ${pxIntoRem(3.4)};
  top: ${pxIntoRem(2)};
  cursor: pointer;

  svg {
    width: ${pxIntoRem(18)};

    path {
      transition-duration: 0.35s;
      fill: ${({ $favourite }) => ($favourite ? "#B90B04" : "#1E1E1E")};
    }
  }
`;

const CarHeaderTitle = styled.h2`
  font-family: Montserrat;
  font-size: ${pxIntoRem(24)};
  font-weight: 700;
  line-height: ${pxIntoRem(29)};
  letter-spacing: 0%;
  text-align: left;
  color: rgb(30, 30, 30);

  span {
    font-family: Montserrat;
    font-size: ${pxIntoRem(24)};
    font-weight: 700;
    line-height: ${pxIntoRem(29)};
    letter-spacing: 0%;
    text-align: left;
    color: rgb(185, 11, 4);
  }
`;

const CarHeaderText = styled.p`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 400;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(5)};

  span {
    color: rgb(86, 86, 86);
    font-family: Montserrat;
    font-size: ${pxIntoRem(12)};
    font-weight: 500;
    line-height: ${pxIntoRem(15)};
    letter-spacing: 0%;
    text-align: left;
  }
`;

const CarInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxIntoRem(14)} ${pxIntoRem(42)};
  margin-top: ${pxIntoRem(24)};
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
  overflow-x: hidden;
`;

const CarInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxIntoRem(10)};
`;

const CarInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(40)};
  height: ${pxIntoRem(40)};
  background: rgb(244, 244, 244);
  border-radius: 100%;

  svg {
    width: ${pxIntoRem(24)};
    height: ${pxIntoRem(24)};
  }
`;

const CarInfoItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CarInfoTitle = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(10)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
`;

const CarInfoValue = styled.span`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(3)};
`;

const CarColors = styled.div`
  display: flex;
  align-items: center;
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
  margin-top: ${pxIntoRem(24)};
`;

const CarColorsTitle = styled.span`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
`;

const CarColorsItems = styled.div`
  display: flex;
  gap: ${pxIntoRem(5)};
  margin-left: ${pxIntoRem(12)};
`;

interface ICarColorsItem {
  $color: string;
}

const CarColorsItem = styled.div<ICarColorsItem>`
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  background: ${({ $color }) => $color};
  width: ${pxIntoRem(25)};
  height: ${pxIntoRem(25)};
  border-radius: 100%;
`;

const CarLine = styled.div`
  margin-top: ${pxIntoRem(23.5)};
  height: ${pxIntoRem(1)};
  width: calc(100% - ${pxIntoRem(28)});
  background-color: rgb(232, 232, 232);
  margin: ${pxIntoRem(23.5)} ${pxIntoRem(14)} ${pxIntoRem(0)} ${pxIntoRem(14)};
`;

const CarPrice = styled.div`
  display: flex;
  align-items: center;
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
  margin-top: ${pxIntoRem(23)};
`;

const CarPriceText = styled.h3`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(24)};
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
`;

const CarPriceCredit = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const CarPriceCreditAlarmIcon = styled(Alarm)`
  width: ${pxIntoRem(14)};
  hgeight: ${pxIntoRem(14)};
`;

const CarPriceCreditAlarmText = styled.span`
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
  margin-left: ${pxIntoRem(5)};
`;

const CarPriceCreditAlarmNotification = styled.div`
  width: calc(100vw - ${pxIntoRem(28)});
  height: auto;
  padding: ${pxIntoRem(12)};
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(10)};
  font-weight: 500;
  line-height: ${pxIntoRem(12)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(7)};
  background: rgb(30, 30, 30);
  position: absolute;
  transition-duration: 0.25s;
  cursor: default;
  position: absolute;
  right: -${pxIntoRem(106)};
  top: -${pxIntoRem(85)};
`;

interface ICarPriceCreditAlarm {
  $open: boolean;
}

const CarPriceCreditAlarm = styled.div<ICarPriceCreditAlarm>`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: ${pxIntoRem(10)};
  border-radius: ${pxIntoRem(30)};
  padding: ${pxIntoRem(5)} ${pxIntoRem(12)};
  height: ${pxIntoRem(25)};
  transition-duration: 0.25s;
  background-color: ${({ $open }) => ($open ? "rgb(185, 11, 4)" : "rgb(30, 30, 30)")};
  cursor: pointer;

  ${CarPriceCreditAlarmNotification} {
    opacity: ${({ $open }) => ($open ? "1" : "0")};
    pointer-events: ${({ $open }) => ($open ? "all" : "none")};
  }
`;

const CarPriceCreditAlarmNotificationImage = styled.img`
  width: ${pxIntoRem(10)};
  right: ${pxIntoRem(145)};
  bottom: -${pxIntoRem(7)};
  position: absolute;
`;

const CarPriceCreditValue = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
`;

const CarButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(12)};
  background: rgb(30, 30, 30);
  height: ${pxIntoRem(53)};
  width: calc(100% - ${pxIntoRem(28)});
  margin: ${pxIntoRem(24)} ${pxIntoRem(14)} ${pxIntoRem(0)} ${pxIntoRem(14)};
  cursor: pointer;
`;

const CarProperties = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${pxIntoRem(40)};
  margin-bottom: ${pxIntoRem(24)};
  overflow-x: hidden;
`;

const CarPropertiesHeader = styled.div`
  display: flex;
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
  gap: ${pxIntoRem(18)};
  overflow-y: scroll;
  // width: calc(100% - ${pxIntoRem(28)});

  //   &::-webkit-scrollbar {
  //   width: 1em;
  // }

  // &::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: darkgrey;
  //   outline: 1px solid slategrey;
  // }
`;

interface ICarPropertyHeaderItem {
  $active: boolean;
}

const CarPropertiesHeaderItem = styled.button<ICarPropertyHeaderItem>`
  border-radius: ${pxIntoRem(100)};
  padding: ${pxIntoRem(8)} ${pxIntoRem(16)};
  background-color: ${({ $active }) => ($active ? "rgb(185, 11, 4)" : "rgb(244, 244, 244)")};
  color: ${({ $active }) => ($active ? "rgb(255, 255, 255)" : "rgb(30, 30, 30)")};
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  flex-shrink: 0;
  cursor: pointer;
  transition-duration: 0.35s;
`;

const CarPropertiesText = styled.div`
  margin-top: ${pxIntoRem(24)};
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)} ${pxIntoRem(0)};
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;

  ul {
    margin-left: ${pxIntoRem(14)};
  }
`;

const CarDealers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(241, 241, 241);
  padding: ${pxIntoRem(40)} ${pxIntoRem(14)} ${pxIntoRem(0)};
`;

const CarDealersTitle = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(18)};
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
`;

const CarDealersText = styled.p`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(5)};
`;

const CarDealersList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${pxIntoRem(24)};
`;

export {
  CarLogo,
  CarBlock,
  CarBack,
  CarBackIcon,
  CarBackText,
  CarSlider,
  CarContent,
  CarColors,
  CarColorsItem,
  CarColorsItems,
  CarColorsTitle,
  CarHeader,
  CarHeaderContent,
  CarHeaderFavourite,
  CarHeaderText,
  CarHeaderTitle,
  CarInfo,
  CarInfoIcon,
  CarInfoItem,
  CarInfoItemContent,
  CarInfoTitle,
  CarInfoValue,
  CarLine,
  CarPriceCredit,
  CarPriceCreditAlarm,
  CarPriceCreditAlarmIcon,
  CarPriceCreditAlarmText,
  CarPriceCreditValue,
  CarPrice,
  CarPriceText,
  CarButton,
  CarPropertiesHeaderItem,
  CarProperties,
  CarPropertiesHeader,
  CarPropertiesText,
  CarDealers,
  CarDealersList,
  CarDealersText,
  CarDealersTitle,
  CarPriceCreditAlarmNotification,
  CarPriceCreditAlarmNotificationImage,
};
