import { FC, SVGProps } from "react";

interface IBrand extends SVGProps<SVGSVGElement> {}

const Brand: FC<IBrand> = ({ ...props }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M16.205 3.35989L10.7334 2.49656C6.1717 1.78489 4.06003 3.32489 3.3367 7.88656L2.47336 13.3582C2.0067 16.3449 2.4967 18.2816 4.18836 19.4599C5.07503 20.0899 6.28836 20.5099 7.86336 20.7549L13.335 21.6182C17.8967 22.3299 20.0084 20.7899 20.7317 16.2282L21.5834 10.7566C21.7234 9.85822 21.7817 9.05322 21.735 8.34156C21.5834 5.42489 19.8684 3.93156 16.205 3.35989ZM9.61336 10.9082C8.24836 10.9082 7.14003 9.79989 7.14003 8.44656C7.14003 7.08156 8.24836 5.97322 9.61336 5.97322C10.9667 5.97322 12.075 7.08156 12.075 8.44656C12.075 9.79989 10.9667 10.9082 9.61336 10.9082Z"
        fill="#1E1E1E"
      />
      <path
        d="M23.9164 15.7149L22.1664 20.9766C20.7081 25.3632 18.3747 26.5299 13.9881 25.0716L8.72644 23.3216C6.07811 22.4466 4.60811 21.2332 4.18811 19.4599C5.07478 20.0899 6.28811 20.5099 7.86311 20.7549L13.3347 21.6182C17.8964 22.3299 20.0081 20.7899 20.7314 16.2282L21.5831 10.7566C21.7231 9.85822 21.7814 9.05322 21.7347 8.34155C24.5231 9.82322 25.1297 12.0632 23.9164 15.7149Z"
        fill="#1E1E1E"
      />
      <path
        d="M12.074 8.44681C12.074 9.80015 10.9657 10.9085 9.61237 10.9085C8.24737 10.9085 7.13904 9.80015 7.13904 8.44681C7.13904 7.08181 8.24737 5.97348 9.61237 5.97348C10.9657 5.97348 12.074 7.08181 12.074 8.44681Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { Brand };
