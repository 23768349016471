import React, { FC, useMemo, useRef, useState } from "react";
import {
  FilterSelectBlock,
  FilterSelectHeader,
  FilterSelectHeaderArrow,
  FilterSelectHeaderTitle,
  FilterSelectItem,
  FilterSelectItemCheckbox,
  FilterSelectItems,
  FilterSelectItemSpecial,
  FilterSelectItemText,
} from "./FilterSelectStyles";
import { DownArrow } from "../../../../SVG/DownArrow";
import { useModal } from "../../../../../hooks/useModal";

interface IFilterSelect {
  title: string;
  chosenValues: any[];
  values: {
    value: any;
    isSpecial?: boolean;
  }[];
  formatValue?: (value: any) => string;
  onChange: (value: any) => void;
  height?: number;
}

const FilterSelect: FC<IFilterSelect> = ({ title, values, formatValue, onChange, chosenValues }) => {
  const getValue = (value: string) => (formatValue ? formatValue(value) : value);

  const [isOpen, openSelect, closeSelect] = useModal();
  const changeOpen = isOpen ? closeSelect : openSelect;

  const height = useMemo(() => {
    const n = values.length;
    return isOpen ? 16 + 40 * n + 10 * (n - 1) : 0;
  }, [isOpen, values]);

  const onSelectChange = (e: React.SyntheticEvent, value: any) => {
    e.stopPropagation();

    if (chosenValues.includes(value)) {
      const newValues = chosenValues.filter((valuesItem) => valuesItem !== value);
      onChange(newValues);
      return;
    }

    const newValues = [...chosenValues, value];
    onChange(newValues);
  };

  return (
    <FilterSelectBlock onClick={changeOpen}>
      <FilterSelectHeader>
        <FilterSelectHeaderTitle>{title}</FilterSelectHeaderTitle>

        <FilterSelectHeaderArrow $open={isOpen}>
          <DownArrow />
        </FilterSelectHeaderArrow>
      </FilterSelectHeader>

      <FilterSelectItems $height={height}>
        {values.map(({ value, isSpecial }) => {
          return (
            <FilterSelectItem key={value} onClick={(e) => onSelectChange(e, value)}>
              <FilterSelectItemCheckbox $checked={chosenValues.includes(value)} />
              <FilterSelectItemText>{getValue(value)}</FilterSelectItemText>

              {isSpecial && <FilterSelectItemSpecial>Специальные условия</FilterSelectItemSpecial>}
            </FilterSelectItem>
          );
        })}
      </FilterSelectItems>
    </FilterSelectBlock>
  );
};

export { FilterSelect };
