import appConfig from "../configs/appConfig";
import { IDealer } from "../types/dealer";

class DealersService {
  static getDealers = async (): Promise<IDealer[]> => {
    const res = await fetch(appConfig.apiUrl + `/dealers`);

    const json = await res.json();

    return json;
  };
}

export { DealersService };
