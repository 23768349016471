class Formatter {
  static formatPrice(price: string | number): string {
    const stringPrice = price.toString();
    if (stringPrice.length < 3) {
      return stringPrice;
    }

    return this.formatPrice(stringPrice.slice(0, stringPrice.length - 3)) + " " + stringPrice.slice(stringPrice.length - 3, stringPrice.length);
  }

  static formateDateIntoYear(date: string) {
    return new Date(date).getFullYear();
  }

  static formatPhoneNumber(number: string | number) {
    const phoneNumber = number.toString();

    const twoFirst = phoneNumber.slice(9);
    const twoSecond = phoneNumber.slice(7, 9);
    const three = phoneNumber.slice(4, 7);
    const threeWrapped = phoneNumber.slice(1, 4);
    const last = phoneNumber.slice(0, 1);

    const result = `${last === "7" ? "+7" : "8"} (${threeWrapped}) ${three}-${twoSecond}-${twoFirst}`;

    return result;
  }

  static getNoun(number: number, one: string, two: string, plural: string) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return plural;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return plural;
  }
}

export default Formatter;
