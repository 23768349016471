import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const ContactContent = styled.div`
  border-radius: ${pxIntoRem(12)} ${pxIntoRem(12)} ${pxIntoRem(0)} ${pxIntoRem(0)};
  background: rgb(255, 255, 255);
  padding: ${pxIntoRem(20)} ${pxIntoRem(14)} ${pxIntoRem(18)};
  margin-top: auto;
  width: 100%;
  padding-bottom: ${pxIntoRem(88)};
`;

const ContactHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContactHeaderTitle = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(18)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
`;

const ContactHeaderClose = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
`;

const ContactHeaderCloseText = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  margin-right: ${pxIntoRem(10)};
`;

const ContactHeaderCloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  border-radius: 100%;
  background: rgb(30, 30, 30, 0.4);

  svg {
    width: ${pxIntoRem(7.16)};
    height: ${pxIntoRem(7.16)};
  }
`;

const ContactInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(14)};
  margin-top: ${pxIntoRem(24)};
  width: 100%;
`;

const ContactInput = styled.input`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  padding: ${pxIntoRem(23)} ${pxIntoRem(14)} ${pxIntoRem(23)} ${pxIntoRem(14)};
  border-radius: ${pxIntoRem(5)};
  background: rgb(241, 241, 241);
  height: ${pxIntoRem(60)};

  &::placeholder {
    color: rgb(30, 30, 30);
    opacity: 1;
  }

  &::-ms-input-placeholder {
    color: rgb(30, 30, 30);
    opacity: 1;
  }
`;

const ContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  height: ${pxIntoRem(53)};
  border-radius: ${pxIntoRem(12)};
  background: rgb(30, 30, 30);
  width: 100%;
  margin-top: ${pxIntoRem(14)};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

interface IContactBlock {
  $open: boolean;
}

const ContactBlock = styled.div<IContactBlock>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  opacity: ${({ $open }) => ($open ? "1" : "0")};
  pointer-events: ${({ $open }) => ($open ? "all" : "none")};
  transition-duration: 0.35s;
`;

export {
  ContactBlock,
  ContactContent,
  ContactHeader,
  ContactHeaderClose,
  ContactHeaderCloseIcon,
  ContactHeaderCloseText,
  ContactHeaderTitle,
  ContactButton,
  ContactInputs,
  ContactInput,
};
