import { FC, SVGProps } from "react";

interface IDownArrow extends SVGProps<SVGSVGElement> {}

const DownArrow: FC<IDownArrow> = ({ ...props }) => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.0002 6.0099C4.8102 6.0099 4.6202 5.9399 4.4702 5.7899L0.940156 2.2599C0.650156 1.9699 0.650156 1.4899 0.940156 1.1999C1.23016 0.909919 1.71016 0.909919 2.00016 1.1999L5.0002 4.1999L8.0002 1.1999C8.2902 0.909919 8.7702 0.909919 9.0602 1.1999C9.3502 1.4899 9.3502 1.9699 9.0602 2.2599L5.5302 5.7899C5.3802 5.9399 5.1902 6.0099 5.0002 6.0099Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { DownArrow };
