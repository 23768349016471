class Clipboard {
  private static fallback(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.display = "none";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }

  public static copy(text: string) {
    if (!navigator.clipboard) {
      Clipboard.fallback(text);
      return;
    }

    navigator.clipboard
      .writeText(text)
      .then(() => console.log("Async: Copying to clipboard was successful!"))
      .catch((err) => console.error("Async: Could not copy text: ", err));
  }
}

export default Clipboard;
