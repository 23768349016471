import styled from "styled-components";
import pxIntoRem from "../../../../../utils/pxIntoRem";
import { DownArrow } from "../../../../SVG/DownArrow";

const ContactSelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
  height: ${pxIntoRem(60)};
  width: 100%;
  background-color: rgb(241, 241, 241);
  border-radius: ${pxIntoRem(5)} ${pxIntoRem(5)} ${pxIntoRem(0)} ${pxIntoRem(0)};
`;

const ContactSelectHeaderText = styled.span`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
`;

const ContactSelectHeaderArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(30, 30, 30, 0.4);
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  border-radius: 100%;
  transition-duration: 0.35s;
`;

const ContactSelectHeaderArrowIcon = styled(DownArrow)`
  width: ${pxIntoRem(14)};
`;

const ContactSelectValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //   position: absolute;
  //   top: ${pxIntoRem(60)};
  //   left: 0;
  transition-duration: 0.35s;
  border-radius: ${pxIntoRem(5)};
`;

interface IContactSelectValue {
  $active: boolean;
}

const ContactSelectValue = styled.div<IContactSelectValue>`
  display: flex;
  align-items: center;
  height: ${pxIntoRem(60)};
  width: 100%;
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  background-color: ${({ $active }) => ($active ? "rgb(231, 230, 230)" : "rgb(241, 241, 241)")};
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};

  &:last-of-type {
    border: none;
  }

  border-top: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  border-bottom: ${pxIntoRem(1)} solid rgb(232, 232, 232);
`;

interface IContactSelectBlock {
  $open: boolean;
}

const ContactSelectBlock = styled.div<IContactSelectBlock>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  cursor: pointer;

  ${ContactSelectHeaderArrow} {
    transform: rotate(${({ $open }) => ($open ? "180deg" : "0deg")});
  }

  ${ContactSelectValues} {
    display: ${({ $open }) => ($open ? "flex" : "none")};
  }
`;

export {
  ContactSelectBlock,
  ContactSelectHeader,
  ContactSelectValue,
  ContactSelectValues,
  ContactSelectHeaderText,
  ContactSelectHeaderArrow,
  ContactSelectHeaderArrowIcon,
};
