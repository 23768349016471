import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

interface IFilterBlock {
  $open: boolean;
}

const FilterBlock = styled.div<IFilterBlock>`
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: ${pxIntoRem(86)};
  overflow-y: scroll;
  opacity: ${({ $open }) => ($open ? "1" : "0")};
  pointer-events: ${({ $open }) => ($open ? "all" : "none")};
  transition-duration: 0.35s;
  z-index: 999999;
`;

const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${pxIntoRem(12)} ${pxIntoRem(12)} ${pxIntoRem(0)} ${pxIntoRem(0)};
  background: rgb(255, 255, 255);
  padding: ${pxIntoRem(0)} ${pxIntoRem(14)};
  flex-grow: 1;
  padding-bottom: ${pxIntoRem(156)};
`;

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  padding-top: ${pxIntoRem(20)};
  padding-bottom: ${pxIntoRem(24)};
`;

const FilterHeaderReset = styled.button`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
`;

const FilterHeaderTitle = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(18)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
  position: relative;
  right: -${pxIntoRem(10)};
`;

const FilterHeaderClose = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
`;

const FilterHeaderCloseText = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  margin-right: ${pxIntoRem(10)};
`;

const FilterHeaderCloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  border-radius: 100%;
  background: rgb(30, 30, 30, 0.4);

  svg {
    width: ${pxIntoRem(7.16)};
    height: ${pxIntoRem(7.16)};
  }
`;

const FilterLine = styled.div`
  margin-top: ${pxIntoRem(25)};
  height: ${pxIntoRem(1)};
  width: 100%;
  background-color: rgb(232, 232, 232);
`;

const FilterSelects = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  height: ${pxIntoRem(53)};
  border-radius: ${pxIntoRem(12)};
  background: rgb(30, 30, 30);
  position: fixed;
  bottom: ${pxIntoRem(88)};
  width: calc(100% - ${pxIntoRem(28)});
  cursor: pointer;
`;

export {
  FilterBlock,
  FilterContent,
  FilterHeader,
  FilterHeaderClose,
  FilterHeaderCloseIcon,
  FilterHeaderCloseText,
  FilterHeaderReset,
  FilterHeaderTitle,
  FilterLine,
  FilterSelects,
  FilterButton,
};
