import React, { FC } from "react";
import { IDealer } from "../../../../types/dealer";
import {
  CarDealerBlock,
  CarDealerButton,
  CarDealerImage,
  CarDealerInfo,
  CarDealerInfoItem,
  CarDealerInfoLink,
  CarDealerInfoText,
  CarDealerInfoTitle,
  CarDealerRating,
  CarDealerRatingCrown,
  CarDealerRatingCrowns,
  CarDealerRatingNumber,
} from "./CarDealerStyles";
import { Copy } from "../../../../components/SVG/Copy";
import Clipboard from "../../../../utils/clipboard";

interface ICarDealer {
  dealer: IDealer;
  onBook: (dealer: IDealer) => void;
}

const CarDealer: FC<ICarDealer> = ({ dealer, onBook }) => {
  const copyPhone = () => {
    Clipboard.copy(dealer?.address ?? "");
  };

  return (
    <CarDealerBlock>
      <CarDealerImage alt="salon" src={dealer.logo} />

      <CarDealerRating>
        <CarDealerRatingNumber>{dealer.rating?.toFixed(1) ?? "0.0"}</CarDealerRatingNumber>

        <CarDealerRatingCrowns>
          {[...new Array(dealer?.rating ?? 0)].map((_, idx) => {
            return <CarDealerRatingCrown key={idx} />;
          })}
        </CarDealerRatingCrowns>
      </CarDealerRating>

      <CarDealerInfo>
        <CarDealerInfoItem>
          <CarDealerInfoTitle>Адрес</CarDealerInfoTitle>
          <CarDealerInfoText>
            {dealer.address}
            <Copy onClick={copyPhone} />
          </CarDealerInfoText>
        </CarDealerInfoItem>

        <CarDealerInfoItem>
          <CarDealerInfoTitle>Автосалон работает</CarDealerInfoTitle>
          <CarDealerInfoText>{dealer.working_hours}</CarDealerInfoText>
        </CarDealerInfoItem>

        <CarDealerInfoItem>
          <CarDealerInfoTitle>Телефон</CarDealerInfoTitle>
          <CarDealerInfoLink href={`tel:${dealer.phone}`}>{dealer.phone}</CarDealerInfoLink>
        </CarDealerInfoItem>
      </CarDealerInfo>

      <CarDealerButton onClick={() => onBook(dealer)}>Забронировать просмотр</CarDealerButton>
    </CarDealerBlock>
  );
};

export { CarDealer };
