import { FC, SVGProps } from "react";

interface IGasPump extends SVGProps<SVGSVGElement> {}

const GasPump: FC<IGasPump> = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.75005 3C5.23125 3 4.00001 4.23122 4.00001 5.75V20.5H3.75001C3.33579 20.5 3 20.8358 3 21.25C3 21.6642 3.33579 22 3.75001 22H17.2502C17.6644 22 18.0002 21.6642 18.0002 21.25C18.0002 20.8358 17.6644 20.5 17.2502 20.5H17.0002V18.6971C17.3763 18.8907 17.8029 19 18.255 19C19.771 19 21 17.771 21 16.255V10.4167C21 9.82166 20.807 9.24269 20.45 8.76668L19.35 7.30001C19.1014 6.96864 18.6313 6.90149 18.3 7.15001C17.9686 7.39854 17.9014 7.86864 18.15 8.20001L19.25 9.66668C19.4123 9.88305 19.5 10.1462 19.5 10.4167V16.255C19.5 16.9426 18.9426 17.5 18.255 17.5C17.5674 17.5 17.01 16.9426 17.01 16.255V14.5C17.01 14.4587 17.0066 14.4182 17.0002 14.3787V5.75C17.0002 4.23122 15.769 3 14.2502 3H6.75005ZM7.00005 6.75C7.00005 6.33579 7.33585 6 7.75006 6H13.2501C13.6644 6 14.0001 6.33579 14.0001 6.75V10.25C14.0001 10.6642 13.6644 11 13.2501 11H7.75006C7.33585 11 7.00005 10.6642 7.00005 10.25V6.75Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { GasPump };
