import { FC, SVGProps } from "react";

interface INavigationDiscont extends SVGProps<SVGSVGElement> {}

const NavigationDiscont: FC<INavigationDiscont> = ({ ...props }) => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M4.32465 14.0491L2.86799 12.5924C2.27382 11.9983 2.27382 11.0208 2.86799 10.4266L4.32465 8.96993C4.57381 8.72076 4.77506 8.232 4.77506 7.887V5.82662C4.77506 4.98329 5.46507 4.29325 6.30841 4.29325H8.36881C8.71381 4.29325 9.20256 4.09203 9.45173 3.84286L10.9083 2.38618C11.5025 1.79201 12.4801 1.79201 13.0743 2.38618L14.5309 3.84286C14.7801 4.09203 15.2688 4.29325 15.6138 4.29325H17.6743C18.5176 4.29325 19.2075 4.98329 19.2075 5.82662V7.887C19.2075 8.232 19.4088 8.72076 19.6579 8.96993L21.1147 10.4266C21.7089 11.0208 21.7089 11.9983 21.1147 12.5924L19.6579 14.0491C19.4088 14.2983 19.2075 14.787 19.2075 15.132V17.1924C19.2075 18.0358 18.5176 18.7258 17.6743 18.7258H15.6138C15.2688 18.7258 14.7801 18.927 14.5309 19.1762L13.0743 20.6328C12.4801 21.227 11.5025 21.227 10.9083 20.6328L9.45173 19.1762C9.20256 18.927 8.71381 18.7258 8.36881 18.7258H6.30841C5.46507 18.7258 4.77506 18.0358 4.77506 17.1924V15.132C4.77506 14.7774 4.57381 14.2887 4.32465 14.0491Z"
        fill="#1E1E1E"
      />
      <path
        d="M14.8751 15.3333C14.3385 15.3333 13.9072 14.902 13.9072 14.375C13.9072 13.8479 14.3385 13.4166 14.8656 13.4166C15.3926 13.4166 15.8239 13.8479 15.8239 14.375C15.8239 14.902 15.4022 15.3333 14.8751 15.3333Z"
        fill="#1E1E1E"
      />
      <path
        d="M9.13467 9.58329C8.59801 9.58329 8.16675 9.15204 8.16675 8.62496C8.16675 8.09788 8.598 7.66663 9.12508 7.66663C9.65216 7.66663 10.0834 8.09788 10.0834 8.62496C10.0834 9.15204 9.66176 9.58329 9.13467 9.58329Z"
        fill="#1E1E1E"
      />
      <path
        d="M9.12456 15.0937C8.94247 15.0937 8.76039 15.0266 8.61664 14.8829C8.33872 14.605 8.33872 14.1449 8.61664 13.867L14.3667 8.11701C14.6446 7.83909 15.1046 7.83909 15.3825 8.11701C15.6604 8.39492 15.6604 8.85498 15.3825 9.1329L9.63247 14.8829C9.48872 15.0266 9.30664 15.0937 9.12456 15.0937Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { NavigationDiscont };
