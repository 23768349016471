import { FC, SVGProps } from "react";

interface IDrive extends SVGProps<SVGSVGElement> {}

const Drive: FC<IDrive> = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 5.5C3 4.11929 4.11929 3 5.5 3C6.88071 3 8 4.11929 8 5.5V6.5H10.063C10.285 5.63739 11.0681 5 12 5C12.9319 5 13.715 5.63739 13.937 6.5H16V5.5C16 4.11929 17.1193 3 18.5 3C19.8807 3 21 4.11929 21 5.5V8.5C21 9.88071 19.8807 11 18.5 11C17.1193 11 16 9.88071 16 8.5V8H13.7324C13.5105 8.38362 13.1648 8.6867 12.75 8.85462V15.1454C13.1648 15.3133 13.5105 15.6164 13.7324 16H16V15.5C16 14.1193 17.1193 13 18.5 13C19.8807 13 21 14.1193 21 15.5V18.5C21 19.8807 19.8807 21 18.5 21C17.1193 21 16 19.8807 16 18.5V17.5H13.937C13.715 18.3626 12.9319 19 12 19C11.0681 19 10.285 18.3626 10.063 17.5H8V18.5C8 19.8807 6.88071 21 5.5 21C4.11929 21 3 19.8807 3 18.5V15.5C3 14.1193 4.11929 13 5.5 13C6.88071 13 8 14.1193 8 15.5V16H10.2676C10.4895 15.6164 10.8352 15.3133 11.25 15.1454V8.85462C10.8352 8.6867 10.4895 8.38362 10.2676 8H8V8.5C8 9.88071 6.88071 11 5.5 11C4.11929 11 3 9.88071 3 8.5V5.5Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { Drive };
