import { FC, PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal: FC<PropsWithChildren> = ({ children }) => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    const element = document.querySelector("body");

    setElement(element ?? null);
  }, []);

  return element ? createPortal(children, element) : null;
};

export default Portal;
