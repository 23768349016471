import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Header from "../../components/Header/Header";
import { Brand, BrandAdv, BrandAdvImage, BrandBlock, BrandBrands, BrandImage } from "./BrandsStyles";
import { Brand as BrandIcon } from "../../components/SVG/Brand";
import { IBrand } from "../../types/brand";
import { IBrandsBanner } from "../../types/banners";
import { BannersService } from "../../services/bannersService";
import Navigation from "../../components/Navigation/Navigation";
import { BrandsService } from "../../services/brandsService";
import { Loader } from "../../components/Modals/Loader/Loader";
import { CarsService } from "../../services/carsService";

const Brands = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [banners, setBanners] = useState<IBrandsBanner[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const getData = async () => {
    const regionId = Number(params.region ?? 0);

    const getBrands = async () => {
      const brands = await BrandsService.getBrands(regionId);
      setBrands(brands);
    };

    const getBanners = async () => {
      const banners = await BannersService.getBrandsBanners(regionId);
      setBanners(banners);
    };

    await Promise.all([getBanners(), getBrands()]);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const brandsChunks = useMemo(() => {
    if (isLoading) return [];

    const brandsResult = [];

    const chunkLength = 4;

    for (let i = 0; i < brands.length; i += chunkLength) {
      brandsResult.push(brands.slice(i, i + chunkLength));
    }

    return brandsResult;
  }, [brands, isLoading]);

  const toBanner = (banner: IBrandsBanner) => window.Telegram.WebApp.openLink(banner.url);
  const toBrand = (brand: IBrand) => {
    CarsService.changeFilter({
      brand_id: brand.id,
      brand_name: brand.name,
    });

    navigate("/cars");
  };

  return (
    <BrandBlock>
      <Loader isOpen={isLoading} />

      <Header title="Выберите марку" subtitle="в Москве и области" icon={<BrandIcon />} onBack={() => {}} />

      {brandsChunks.map((chunk, idx) => {
        const banner = banners[idx] ?? null;

        return (
          <React.Fragment key={idx}>
            <BrandBrands>
              {chunk.map((brand) => {
                return (
                  <Brand onClick={() => toBrand(brand)} key={brand.id}>
                    <BrandImage alt="brand" src={brand.cover_image} />
                  </Brand>
                );
              })}
            </BrandBrands>

            {banner && (
              <BrandAdv onClick={() => toBanner(banner)}>
                <BrandAdvImage alt="banner" src={banner.image} />
              </BrandAdv>
            )}
          </React.Fragment>
        );
      })}

      <Navigation />
    </BrandBlock>
  );
};

export { Brands };
