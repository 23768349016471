import { FC, SVGProps } from "react";

interface IRegion extends SVGProps<SVGSVGElement> {}

const Region: FC<IRegion> = ({ ...props }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M24.0557 9.85832C22.8307 4.46832 18.129 2.04166 13.999 2.04166C13.999 2.04166 13.999 2.04166 13.9873 2.04166C9.86901 2.04166 5.15568 4.45666 3.93068 9.84666C2.56568 15.8667 6.25235 20.965 9.58901 24.1733C10.8257 25.3633 12.4123 25.9583 13.999 25.9583C15.5857 25.9583 17.1723 25.3633 18.3973 24.1733C21.734 20.965 25.4207 15.8783 24.0557 9.85832Z"
        fill="#1E1E1E"
      />
      <path
        d="M13.9996 15.7036C16.0292 15.7036 17.6746 14.0582 17.6746 12.0286C17.6746 9.99887 16.0292 8.35352 13.9996 8.35352C11.9699 8.35352 10.3246 9.99887 10.3246 12.0286C10.3246 14.0582 11.9699 15.7036 13.9996 15.7036Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { Region };
