import React, { FC, useState } from "react";
import {
  ContactSelectBlock,
  ContactSelectHeader,
  ContactSelectHeaderArrow,
  ContactSelectHeaderArrowIcon,
  ContactSelectHeaderText,
  ContactSelectValue,
  ContactSelectValues,
} from "./ContactSelectStyles";
import { useModal } from "../../../../../hooks/useModal";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";

interface IContactSelect {
  placeholder: string;
  value: string | null;
  values: string[];
  onChange(value: string): void;
}

const ContactSelect: FC<IContactSelect> = ({ placeholder, value, values, onChange }) => {
  const [open, openSelect, closeSelect] = useModal();
  const changeSelect = open ? closeSelect : openSelect;

  const ref = useOutsideClick(closeSelect);

  const onSelectChange = (value: string) => {
    onChange(value);
    closeSelect();
  };

  return (
    <ContactSelectBlock $open={open} onClick={changeSelect} ref={ref}>
      <ContactSelectHeader>
        <ContactSelectHeaderText>{value ?? placeholder}</ContactSelectHeaderText>

        <ContactSelectHeaderArrow>
          <ContactSelectHeaderArrowIcon />
        </ContactSelectHeaderArrow>
      </ContactSelectHeader>

      <ContactSelectValues>
        {values.map((listValue) => {
          return (
            <ContactSelectValue $active={listValue === value} onClick={() => onSelectChange(listValue)} key={listValue}>
              {listValue}
            </ContactSelectValue>
          );
        })}
      </ContactSelectValues>
    </ContactSelectBlock>
  );
};

export { ContactSelect };
