import styled from "styled-components";
import pxIntoRem from "../../../../../utils/pxIntoRem";

const FilterRangeBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${pxIntoRem(24)};
  border-bottom: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  padding-top: ${pxIntoRem(26)};
`;

const FilterRangeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const FilterRangeHeaderTitle = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 700;
  line-height: ${pxIntoRem(14)};
  letter-spacing: 0%;
  text-align: left;
`;

interface IFilterRangeHeaderArrow {
  $open: boolean;
}

const FilterRangeHeaderArrow = styled.div<IFilterRangeHeaderArrow>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  background-color: rgb(30, 30, 30, 0.4);
  border-radius: 100%;
  transition-duration: 0.35s;
  transform: rotate(${({ $open }) => ($open ? "180deg" : "0deg")});

  svg {
    width: ${pxIntoRem(8.56)};
    height: ${pxIntoRem(5.03)};
  }
`;

interface IFilterRangeContent {
  $height: number;
}

const FilterRangeContent = styled.div<IFilterRangeContent>`
  width: 100%;
  height: ${({ $height }) => pxIntoRem($height)};
  transition-duration: 0.35s;
  overflow: hidden;
`;

const FilterRangeInputBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${pxIntoRem(7)};
  background: rgb(244, 244, 244);
  height: ${pxIntoRem(48)};
  width: 100%;
  margin-top: ${pxIntoRem(16)};
`;

const FilterRangeInputColumnSplitter = styled.div`
  width: ${pxIntoRem(1)};
  height: ${pxIntoRem(17)};
  background-color: rgb(86, 86, 86);
`;

const FilterRangeInputColumnInput = styled.input`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(16)};
  font-weight: 500;
  line-height: ${pxIntoRem(20)};
  letter-spacing: 0%;
  text-align: center;
  background-color: transparent;
  max-width: ${pxIntoRem(180)};
  padding: ${pxIntoRem(16)};
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const FilterRangeInputColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;

  &:nth-child(1) {
    justify-content: flex-start;

    ${FilterRangeInputColumnInput} {
      text-align: start;
    }
  }

  &:nth-child(3)  {
    justify-content: flex-end;

    ${FilterRangeInputColumnInput} {
      text-align: end;
    }
  }
`;

const FilterRangeInputRange = styled.div`
  position: absolute;
  bottom: ${pxIntoRem(0)};
  height: ${pxIntoRem(1)};
  width: 90%;
`;

const FilterRangeInputRangeProgress = styled.div`
  position: absolute;
  bottom: -${pxIntoRem(1)};
  height: ${pxIntoRem(1)};
  width: 100%;
  background-color: rgb(185, 11, 4);
`;

const FilterRangeInputRangeInputs = styled.div`
  display: block;
  width: 92%;
  position: absolute;
  bottom: -${pxIntoRem(1)};
`;

const FilterRangeInputRangeInput = styled.input`
  display: block;
  width: 100%;
  height: ${pxIntoRem(1)};
  background: none;
  position: absolute;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  pointer-events: none;

  &::-webkit-slider-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    width: ${pxIntoRem(5.38)};
    height: ${pxIntoRem(5.38)};
    border-radius: 100%;
    background: rgb(185, 11, 4);
    border: ${pxIntoRem(4.31)} solid rgb(185, 11, 4);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    width: ${pxIntoRem(5.38)};
    height: ${pxIntoRem(5.38)};
    border-radius: 100%;
    background: #ffffff;
    border: ${pxIntoRem(4.31)} solid rgb(185, 11, 4);
    cursor: pointer;
  }

  &::-ms-thumb {
    pointer-events: all;
    -webkit-appearance: none;
    width: ${pxIntoRem(5.38)};
    height: ${pxIntoRem(5.38)};
    border-radius: 100%;
    background: #ffffff;
    border: ${pxIntoRem(4.31)} solid rgb(185, 11, 4);
    cursor: pointer;
  }
`;

export {
  FilterRangeBlock,
  FilterRangeHeader,
  FilterRangeHeaderArrow,
  FilterRangeHeaderTitle,
  FilterRangeContent,
  FilterRangeInputBody,
  FilterRangeInputColumn,
  FilterRangeInputColumnInput,
  FilterRangeInputColumnSplitter,
  FilterRangeInputRange,
  FilterRangeInputRangeInput,
  FilterRangeInputRangeInputs,
  FilterRangeInputRangeProgress,
};
