
import { FC, SVGProps } from "react";

interface IHeart extends SVGProps<SVGSVGElement> {}

const Heart: FC<IHeart> = ({ ...props }) => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}> 
    <path d="M9.5495 16.3332C9.2495 16.4332 8.73283 16.4332 8.43283 16.3332C5.83283 15.4498 -0.000488281 11.7332 -0.000488281 5.43317C-0.000488281 2.64988 2.23285 0.399899 4.99951 0.399899C6.63283 0.399899 8.08283 1.18322 8.9995 2.41655C9.8995 1.19988 11.3662 0.399899 12.9995 0.399899C15.7662 0.399899 17.9995 2.64988 17.9995 5.43317C17.9995 11.7332 12.1662 15.4498 9.5495 16.3332Z" fill="#1E1E1E"/>
    </svg>
  );
};

export { Heart };
