import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

interface IPreloaderBlock {
  $open: boolean;
}

const PreloaderBlock = styled.div<IPreloaderBlock>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(185, 11, 4);
  opacity: ${({ $open }) => ($open ? "1" : "0")};
  pointer-events: ${({ $open }) => ($open ? "all" : "none")};
  padding-top: ${pxIntoRem(12)};
  transition-duration: 0.35s;
  z-index: 99999999999;
`;

const PreloaderImage = styled.img`
  width: ${pxIntoRem(300)};
  height: ${pxIntoRem(164)};
  object-fit: cover;
`;

export { PreloaderBlock, PreloaderImage };
