import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Percent as PercentIcon } from "../../components/SVG/Percent";
import { SuggestionBlock, SuggestionList } from "./SuggestionStyles";
import Navigation from "../../components/Navigation/Navigation";
import { ICar } from "../../types/car";
import { CarsService } from "../../services/carsService";
import { Loader } from "../../components/Modals/Loader/Loader";
import { Car } from "../../components/Car/Car";

const Suggestion = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [cars, setCars] = useState<ICar[]>([]);

  const getData = async () => {
    const getCars = async () => {
      const cars = await CarsService.getDeals();
      console.log(cars);

      setCars(cars);
    };

    await Promise.all([getCars()]);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <SuggestionBlock>
      <Loader isOpen={isLoading} />

      <Header title="Предложение дня" subtitle="Уникальные предложения от дилеров" icon={<PercentIcon />} />

      <SuggestionList>
        {cars.map((car) => {
          return <Car car={car} onChange={getData} key={car.vin + car.price} isDiscount />;
        })}
      </SuggestionList>

      <Navigation isFilter />
    </SuggestionBlock>
  );
};

export { Suggestion };
