import appConfig from "../configs/appConfig";
import { IBrand } from "../types/brand";

class BrandsService {
  static getBrands = async (regionId: number): Promise<IBrand[]> => {
    const res = await fetch(appConfig.apiUrl + `/brands/${regionId}`);

    const json = await res.json();

    return json;
  };
}

export { BrandsService };
