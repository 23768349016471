import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { Cars as CarsIcon } from "../../components/SVG/Cars";
import { CarsBlock, CarsList } from "./CarsStyles";
import { Car } from "../../components/Car/Car";
import Navigation from "../../components/Navigation/Navigation";
import { CarsService, ICarsFilter } from "../../services/carsService";
import { ICar } from "../../types/car";
import { Loader } from "../../components/Modals/Loader/Loader";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const Cars = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState<ICar[]>([]);
  const [brandName, setBrandName] = useState("");

  const getData = async () => {
    const queryObj: any = {};

    query.forEach((value, key) => {
      queryObj[key] = value;
    });

    const filter = Object.keys(queryObj).length ? (queryObj as any as ICarsFilter) : null;

    if (filter) {
      const brandId = query.get("brand_id");
      const brandName = query.get("brand_name");
      const dealerId = query.getAll("dealer_id").map(Number);
      const transmission = query.getAll("transmission");
      const engineType = query.getAll("engine_type");
      const bodyType = query.getAll("body_type");
      const priceMin = query.get("price_min");
      const priceMax = query.get("price_max");
      const engineVolumeMax = query.get("engine_volume_max");
      const engineVolumeMin = query.get("engine_volume_min");

      CarsService.clearFilter();
      CarsService.changeFilter({
        brand_id: Number(brandId) || undefined,
        brand_name: brandName || undefined,
        dealer_id: dealerId.length ? dealerId : undefined,
        transmission: transmission.length ? transmission : undefined,
        engine_type: engineType.length ? engineType : undefined,
        body_type: bodyType.length ? bodyType : undefined,
        price_min: Number(priceMin) || undefined,
        price_max: Number(priceMax) || undefined,
        engine_volume_max: engineVolumeMax || undefined,
        engine_volume_min: engineVolumeMin || undefined,
      });
    }

    const getCars = async () => {
      const cars = await CarsService.getCars();

      setCars(cars);
    };

    const getBrandName = () => {
      const { brand_name } = CarsService.getFilter();
      setBrandName(brand_name);
    };

    await Promise.allSettled([getCars(), getBrandName()]);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const back = () => navigate(-1);

  return (
    <CarsBlock>
      <Loader isOpen={isLoading} />

      <Header title="Выберите автомобиль" subtitle={`марки ${brandName}`} icon={<CarsIcon />} onBack={back} />

      <CarsList>
        {cars.map((car) => {
          return <Car car={car} onChange={getData} key={car.vin + car.price} />;
        })}
      </CarsList>

      <Navigation isFilter onFilterChange={getData} />
    </CarsBlock>
  );
};

export { Cars };
