import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CarsService } from "../../../services/carsService";
import { IDealer } from "../../../types/dealer";
import Portal from "../../Portal/Portal";
import { Close } from "../../SVG/Close";
import {
  FilterBlock,
  FilterButton,
  FilterContent,
  FilterHeader,
  FilterHeaderClose,
  FilterHeaderCloseIcon,
  FilterHeaderCloseText,
  FilterHeaderReset,
  FilterHeaderTitle,
  FilterSelects,
} from "./FilterStyles";
import { FilterRange } from "./components/FilterRange/FilterRange";
import { FilterSelect } from "./components/FilterSelect/FilterSelect";

interface IFilter {
  isOpen: boolean;
  onClose: () => any;
  onChange?: () => any;
  dealers: IDealer[];
}

const Filter: FC<IFilter> = ({ isOpen, onClose, onChange, dealers }) => {
  const navigate = useNavigate();
  const [dealerIds, setDealerIds] = useState<number[]>([]);
  const [transmissions, setTransmissions] = useState<string[]>([]);
  const [engineTypes, setEngineTypes] = useState<string[]>([]);
  const [bodyTypes, setBodyTypes] = useState<string[]>([]);

  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [defaultMaxPrice, setDefaultMaxPrice] = useState(0);
  const [defaultMinPrice, setDefaultMinPrice] = useState(0);

  const [minEngineVolume, setMinEngineVolume] = useState(0);
  const [maxEngineVolume, setMaxEngineVolume] = useState(0);
  const [defaultMinEngineVolume, setDefaultMinEngineVolume] = useState(0);
  const [defaultMaxEngineVolume, setDefaultMaxEngineVolume] = useState(0);

  const [defaultTransmissions, setDefaultTransmissions] = useState<string[]>([]);
  const [defaultEngineTypes, setDefaultEngineTypes] = useState<string[]>([]);
  const [defaultBodyTypes, setDefaultBodyTypes] = useState<string[]>([]);

  useEffect(() => {
    const getData = async () => {
      const getFilter = async () => {
        const filter = CarsService.getFilter();

        setMinPrice((filter?.price_min as any) ?? null);
        setMaxPrice((filter?.price_max as any) ?? null);
        setMinEngineVolume((filter?.engine_volume_min as any) ?? null);
        setMaxEngineVolume((filter?.engine_volume_max as any) ?? null);
        setDealerIds(filter?.dealer_id ?? []);
        setTransmissions(filter?.transmission ?? []);
        setEngineTypes(filter?.engine_type ?? []);
        setBodyTypes(filter?.body_type ?? []);
      };

      const getRanges = async () => {
        const { min_price, max_price, min_engine_volume, max_engine_volume, engine_types, body_types, transmissions } =
          await CarsService.getDefaultFilters();

        setDefaultTransmissions(transmissions);
        setDefaultBodyTypes(body_types);
        setDefaultEngineTypes(engine_types);

        if (!minPrice) setMinPrice(min_price);
        if (!maxPrice) setMaxPrice(max_price);

        setDefaultMinPrice(min_price);
        setDefaultMaxPrice(max_price);

        if (!minEngineVolume) setMinEngineVolume(min_engine_volume);
        if (!maxEngineVolume) setMaxEngineVolume(max_engine_volume);

        setDefaultMinEngineVolume(min_engine_volume);
        setDefaultMaxEngineVolume(max_engine_volume);
      };

      await getFilter();
      await getRanges();
    };

    getData();
  }, []);

  const onClear = () => {
    setDealerIds([]);
    setTransmissions([]);
    setEngineTypes([]);
    setBodyTypes([]);
    setMinPrice(defaultMinPrice);
    setMaxPrice(defaultMaxPrice);
    setMinEngineVolume(defaultMinEngineVolume);
    setMaxEngineVolume(defaultMaxEngineVolume);
  };

  const onFilterChange = async () => {
    CarsService.changeFilter({
      dealer_id: dealerIds.length ? dealerIds : undefined,
      transmission: transmissions.length ? transmissions : undefined,
      engine_type: engineTypes.length ? engineTypes : undefined,
      body_type: bodyTypes.length ? bodyTypes : undefined,
      price_min: minPrice ?? undefined,
      price_max: maxPrice ?? undefined,
      engine_volume_max: maxEngineVolume?.toString() ?? undefined,
      engine_volume_min: minEngineVolume?.toString() ?? undefined,
    });

    navigate("/cars");

    onChange && (await onChange());
    onClose();
  };

  return (
    <Portal>
      <FilterBlock $open={isOpen} onClick={onClose}>
        <FilterContent onClick={(e) => e.stopPropagation()}>
          <FilterHeader>
            <FilterHeaderReset onClick={onClear}>Сбросить</FilterHeaderReset>

            <FilterHeaderTitle>Параметры</FilterHeaderTitle>

            <FilterHeaderClose onClick={onClose}>
              <FilterHeaderCloseText>Закрыть</FilterHeaderCloseText>
              <FilterHeaderCloseIcon>
                <Close />
              </FilterHeaderCloseIcon>
            </FilterHeaderClose>
          </FilterHeader>

          <FilterSelects>
            <FilterSelect
              title="Дилер"
              chosenValues={dealerIds}
              values={dealers.map((dealer) => ({
                value: dealer.id,
                isSpecial: !!dealer.special,
              }))}
              height={106}
              onChange={setDealerIds}
              formatValue={(dealerId) => dealers.find(({ id }) => id === dealerId)?.name ?? ""}
            />

            <FilterSelect
              title="Коробка передач"
              chosenValues={transmissions}
              values={defaultTransmissions.map((value) => ({
                value,
              }))}
              onChange={setTransmissions}
            />

            <FilterSelect
              title="Тип кузова"
              chosenValues={bodyTypes}
              values={defaultBodyTypes.map((value) => ({
                value,
              }))}
              onChange={setBodyTypes}
            />

            <FilterSelect
              title="Тип двигателя"
              chosenValues={engineTypes}
              values={defaultEngineTypes.map((value) => ({
                value,
              }))}
              onChange={setEngineTypes}
            />

            <FilterRange
              title="Цена"
              maxValue={defaultMaxPrice}
              minValue={defaultMinPrice}
              max={maxPrice}
              min={minPrice}
              onChange={(minPrice, maxPrice) => {
                setMaxPrice(maxPrice);
                setMinPrice(minPrice);
              }}
              step={1}
              numberFields={false}
            />

            <FilterRange
              title="Объем двигателя (л.)"
              maxValue={defaultMaxEngineVolume}
              minValue={defaultMinEngineVolume}
              max={maxEngineVolume}
              min={minEngineVolume}
              onChange={(minEngineVolume, maxEngineVolume) => {
                setMaxEngineVolume(maxEngineVolume);
                setMinEngineVolume(minEngineVolume);
              }}
              step={0.01}
              numberFields={false}
            />
          </FilterSelects>

          <FilterButton onClick={onFilterChange}>Фильтровать</FilterButton>
        </FilterContent>
      </FilterBlock>
    </Portal>
  );
};

export { Filter };
