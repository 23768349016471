import { useState, useMemo } from "react";

function usePhoneInput() {
  const [phone, setPhone] = useState("");

  const notFormattedPhone = useMemo(() => {
    return phone.replace(/\D/g, "");
  }, [phone]);

  function onPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    let intValue = e.currentTarget.value.replace(/\D/g, "");

    let selectionStart = e.currentTarget.selectionStart;
    let formattedInputValue = "";

    if (!intValue) {
      setPhone("");
      return;
    }

    if (value.length !== selectionStart) {
      // if (/\D/g.test(value)) {
      // setPhone(value);
      // }
      return;
    }

    // const knownPrefixes = ["7"];
    // if (knownPrefixes.includes(intValue[0])) {
    // if (intValue[0] == "9") {
    //   intValue = "7" + intValue;
    // }

    if (intValue.length == 1) {
      formattedInputValue = "+7 (" + intValue[0];
    } else {
      formattedInputValue = "+7 (";
    }

    if (intValue.length > 1) {
      formattedInputValue += intValue.substring(1, 4);
    }

    if (intValue.length >= 5) {
      formattedInputValue += ") " + intValue.substring(4, 7);
    }

    if (intValue.length >= 8) {
      formattedInputValue += "-" + intValue.substring(7, 9);
    }

    if (intValue.length >= 10) {
      formattedInputValue += "-" + intValue.substring(9, 11);
    }

    setPhone(formattedInputValue);
  }

  function onPhoneKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const value = e.currentTarget.value.replace(/\D/g, "");

    if ((e.key === "Backspace" || e.key === "Delete") && value.length == 2) {
      setPhone("");
    }
  }

  return {
    phone,
    notFormattedPhone,
    onPhoneChange,
    onPhoneKeyDown,
  };
}

export default usePhoneInput;
