import React, { useEffect, useState, useRef } from "react";

function useDraggableScroll() {
  const sliderRef = useRef<HTMLDivElement>(null);
  const mouseDown = useRef<boolean>(false);
  const dragged = useRef<boolean>(false);
  const scrollLeft = useRef<any>(null);
  const startX = useRef<any>(null);

  function stopClick(e: any) {
    if (!sliderRef.current) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    sliderRef.current.removeEventListener("click", stopClick);
  }

  function onMouseDown(e: any) {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.style.scrollSnapType = "none";

    mouseDown.current = true;

    startX.current = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft.current = sliderRef.current.scrollLeft;
  }

  function onMouseUp(e: any) {
    if (!sliderRef.current) {
      return;
    }

    mouseDown.current = false;
  }

  function onMouseLeave(e: any) {
    if (!sliderRef.current) {
      return;
    }

    mouseDown.current = false;

    if (dragged.current) {
      sliderRef.current.removeEventListener("click", stopClick);
    }
  }

  function onMouseMove(e: any) {
    e.preventDefault();
    if (!mouseDown.current || !sliderRef.current || !startX.current) {
      return;
    }

    dragged.current = true;
    sliderRef.current.addEventListener("click", stopClick);

    const x = e.pageX - sliderRef.current.offsetLeft;
    const scroll = x - startX.current;

    sliderRef.current.scrollLeft = scrollLeft.current - scroll;
  }

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.addEventListener("mousemove", onMouseMove);
    sliderRef.current.addEventListener("mousedown", onMouseDown, false);
    sliderRef.current.addEventListener("mouseleave", onMouseLeave, false);
    sliderRef.current.addEventListener("mouseup", onMouseUp, false);

    return () => {
      if (!sliderRef.current) {
        return;
      }

      sliderRef.current.removeEventListener("mousemove", onMouseMove);
      sliderRef.current.removeEventListener("mousedown", onMouseDown, false);
      sliderRef.current.removeEventListener("mouseleave", onMouseLeave, false);
      sliderRef.current.removeEventListener("mouseup", onMouseUp, false);
    };
  }, []);

  return {
    sliderRef,
  };
}

export default useDraggableScroll;
