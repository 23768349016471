import { FC, SVGProps } from "react";

interface IEngine extends SVGProps<SVGSVGElement> {}

const Engine: FC<IEngine> = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.75 3C10.1642 3 10.5 3.33579 10.5 3.75V5H12.5V3.75C12.5 3.33579 12.8358 3 13.25 3C13.6642 3 14 3.33579 14 3.75V5H16.75C17.0567 5 17.3325 5.18671 17.4464 5.47146L18.2578 7.5H19.25C20.7688 7.5 22 8.73122 22 10.25V15.75C22 17.2688 20.7688 18.5 19.25 18.5H18.2578L17.4464 20.5285C17.3325 20.8133 17.0567 21 16.75 21H9.25C9.04211 21 8.84355 20.9137 8.70171 20.7617L5.7396 17.588C5.26433 17.0788 5 16.4082 5 15.7117V12.5H3.5V16.25C3.5 16.6642 3.16421 17 2.75 17C2.33579 17 2 16.6642 2 16.25V7.75C2 7.33579 2.33579 7 2.75 7C3.16421 7 3.5 7.33579 3.5 7.75V11H5V7.75C5 6.23122 6.23122 5 7.75 5H9V3.75C9 3.33579 9.33579 3 9.75 3ZM10.5 9.75C10.5 9.33579 10.1642 9 9.75 9C9.33579 9 9 9.33579 9 9.75V12.25C9 13.7688 10.2312 15 11.75 15H18.25C18.6642 15 19 14.6642 19 14.25C19 13.8358 18.6642 13.5 18.25 13.5H14V9.75C14 9.33579 13.6642 9 13.25 9C12.8358 9 12.5 9.33579 12.5 9.75V13.5H11.75C11.0596 13.5 10.5 12.9404 10.5 12.25V9.75Z"
        fill="#1E1E1E"
      />
    </svg>
  );
};

export { Engine };
