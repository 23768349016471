import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";
import { Crown } from "../../../../components/SVG/Crown";

const CarDealerBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${pxIntoRem(18)} ${pxIntoRem(14)};
  border-radius: ${pxIntoRem(12)};
  background: rgb(255, 255, 255);
`;

const CarDealerImage = styled.img`
  max-height: ${pxIntoRem(30)};
`;

const CarDealerRating = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${pxIntoRem(12)};
`;

const CarDealerRatingNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 700;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(7)};
  background: rgb(244, 244, 244);
  width: ${pxIntoRem(46)};
  height: ${pxIntoRem(41)};
`;

const CarDealerRatingCrowns = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${pxIntoRem(15)};
  gap: ${pxIntoRem(5)};
`;

const CarDealerRatingCrown = styled(Crown)`
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
`;

const CarDealerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(14)};
  margin-top: ${pxIntoRem(18)};
`;

const CarDealerInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(5)};
`;

const CarDealerInfoTitle = styled.h3`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 700;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
`;

const CarDealerInfoText = styled.p`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0%;
  text-align: left;

  svg {
    cursor: pointer;
    margin-left: ${pxIntoRem(8)};
    width: ${pxIntoRem(12)};
    height: ${pxIntoRem(12)};
  }
`;

const CarDealerInfoLink = styled.a`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0%;
  text-align: left;

  svg {
    cursor: pointer;
    margin-left: ${pxIntoRem(8)};
    width: ${pxIntoRem(12)};
    height: ${pxIntoRem(12)};
  }
`;

const CarDealerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxIntoRem(53)};
  width: 100%;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 400;
  line-height: ${pxIntoRem(17)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(12)};
  background: rgb(30, 30, 30);
  margin-top: ${pxIntoRem(18)};
  cursor: pointer;
`;

export {
  CarDealerBlock,
  CarDealerButton,
  CarDealerImage,
  CarDealerInfo,
  CarDealerInfoItem,
  CarDealerInfoText,
  CarDealerInfoLink,
  CarDealerInfoTitle,
  CarDealerRating,
  CarDealerRatingCrown,
  CarDealerRatingCrowns,
  CarDealerRatingNumber,
};
