import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import App from "./App";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');

  * {
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box!important;
    font-family: 'Montserrat';
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  body, html, #root {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: white;
    font-size: 17px;

    @media(max-width: 428px) {
      font-size: 16px;
    }

    @media(max-width: 390px) {
      font-size: 15px;
    }

    @media(max-width: 370px) {
      font-size: 14px;
    }

    @media(max-width: 350px) {
      font-size: 13px;
    }

    @media(max-width: 330px) {
      font-size: 12px;
    }

    @media(max-width: 310px) {
      font-size: 11px;
    }
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
