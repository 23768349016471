import styled from "styled-components";
import pxIntoRem from "../../../../../utils/pxIntoRem";

const FilterSelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${pxIntoRem(24)};
  border-bottom: ${pxIntoRem(1)} solid rgb(232, 232, 232);
  padding-top: ${pxIntoRem(26)};
  cursor: pointer;
`;

const FilterSelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const FilterSelectHeaderTitle = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 700;
  line-height: ${pxIntoRem(14)};
  letter-spacing: 0%;
  text-align: left;
`;

interface IFilterSelectHeaderArrow {
  $open: boolean;
}

const FilterSelectHeaderArrow = styled.div<IFilterSelectHeaderArrow>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(20)};
  height: ${pxIntoRem(20)};
  background-color: rgb(30, 30, 30, 0.4);
  border-radius: 100%;
  transition-duration: 0.35s;
  transform: rotate(${({ $open }) => ($open ? "180deg" : "0deg")});

  svg {
    width: ${pxIntoRem(8.56)};
    height: ${pxIntoRem(5.03)};
  }
`;

interface IFilterSelectItems {
  $height: number;
}

const FilterSelectItems = styled.div<IFilterSelectItems>`
  display: flex;
  flex-direction: column;
  gap: ${pxIntoRem(10)};
  height: ${({ $height }) => pxIntoRem($height)};
  overflow: hidden;
  transition-duration: 0.35s;
`;

const FilterSelectItem = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  
  &:first-of-type {
    padding-top: ${pxIntoRem(16)};
  }
`;

interface IFilterSelectItemCheckbox {
  $checked: boolean;
}

const FilterSelectItemCheckbox = styled.div<IFilterSelectItemCheckbox>`
  box-sizing: border-box;
  border: ${pxIntoRem(1)} solid rgba(0, 0, 0, 0.12);
  border-radius: ${pxIntoRem(5)};
  width: ${pxIntoRem(40)};
  height: ${pxIntoRem(40)};
  background-color: ${({ $checked }) => ($checked ? "#B90B04" : "rgb(255, 255, 255)")};
  transition-duration: 0.35s;
`;

const FilterSelectItemText = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  margin-left: ${pxIntoRem(10)};
`;

const FilterSelectItemSpecial = styled.span`
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: ${pxIntoRem(12)};
  font-weight: 400;
  line-height: ${pxIntoRem(14)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(20)};
  margin-left: auto;
  border-radius: ${pxIntoRem(20)};
  background: rgb(185, 11, 4);
  height: ${pxIntoRem(26)};
  padding: ${pxIntoRem(6)} ${pxIntoRem(12)};
`;

export {
  FilterSelectBlock,
  FilterSelectHeader,
  FilterSelectHeaderArrow,
  FilterSelectHeaderTitle,
  FilterSelectItem,
  FilterSelectItemCheckbox,
  FilterSelectItemSpecial,
  FilterSelectItemText,
  FilterSelectItems,
};
