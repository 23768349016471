import styled, { keyframes } from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

interface ILoaderBlock {
  $open: boolean;
}

const LoaderBlock = styled.div<ILoaderBlock>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  padding-bottom: ${pxIntoRem(76)};
  z-index: 9999;
  background-color: rgb(0, 0, 0, 0.4);
  opacity: ${({ $open }) => ($open ? "1" : "0")};
  pointer-events: ${({ $open }) => ($open ? "all" : "none")};
  transition-duration: 0.35s;
  z-index: 999999999;
`;

const LoaderImageBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: relative;
`;

const LoaderImage = styled.img`
  width: ${pxIntoRem(140)};
  height: ${pxIntoRem(18)};
  z-index: 999;
`;

const circle = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
    }
`;

interface ILoaderCircle {
  $delay: number;
}

const LoaderCircle = styled.div<ILoaderCircle>`
  position: absolute;
  animation-name: ${circle};
  animation-duration: 6000ms;
  animation-delay: ${({ $delay }) => $delay}ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-color: rgb(185, 11, 4);
  border-radius: 100%;
  width: ${pxIntoRem(200)};
  height: ${pxIntoRem(200)};
  opacity: 0;
`;

export { LoaderBlock, LoaderImageBlock, LoaderImage, LoaderCircle };
