import { FC, SVGProps } from "react";

interface IAlarm extends SVGProps<SVGSVGElement> {}

const Alarm: FC<IAlarm> = ({ ...props }) => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.4"
        d="M12.6935 9.78667L8.96013 3.06667C8.45846 2.16251 7.76429 1.66667 7.00013 1.66667C6.23596 1.66667 5.54178 2.16251 5.04011 3.06667L1.30678 9.78667C0.834276 10.6442 0.781776 11.4667 1.16094 12.1142C1.54011 12.7617 2.28678 13.1175 3.26678 13.1175H10.7335C11.7135 13.1175 12.4601 12.7617 12.8393 12.1142C13.2185 11.4667 13.166 10.6383 12.6935 9.78667Z"
        fill="white"
      />
      <path
        d="M7 9.10417C6.76083 9.10417 6.5625 8.90583 6.5625 8.66667V5.75C6.5625 5.51083 6.76083 5.3125 7 5.3125C7.23917 5.3125 7.4375 5.51083 7.4375 5.75V8.66667C7.4375 8.90583 7.23917 9.10417 7 9.10417Z"
        fill="white"
      />
      <path
        d="M6.99984 11.0001C6.96484 11.0001 6.924 10.9942 6.88317 10.9884C6.84817 10.9826 6.81317 10.9709 6.77817 10.9534C6.74317 10.9417 6.70817 10.9242 6.67317 10.9009C6.644 10.8776 6.61484 10.8542 6.58567 10.8309C6.48067 10.7201 6.4165 10.5684 6.4165 10.4167C6.4165 10.2651 6.48067 10.1134 6.58567 10.0026C6.61484 9.97923 6.644 9.9559 6.67317 9.93256C6.70817 9.90923 6.74317 9.89173 6.77817 9.88006C6.81317 9.86256 6.84817 9.8509 6.88317 9.84506C6.959 9.82756 7.04067 9.82756 7.11067 9.84506C7.1515 9.8509 7.1865 9.86256 7.2215 9.88006C7.2565 9.89173 7.2915 9.90923 7.3265 9.93256C7.35567 9.9559 7.38484 9.97923 7.414 10.0026C7.519 10.1134 7.58317 10.2651 7.58317 10.4167C7.58317 10.5684 7.519 10.7201 7.414 10.8309C7.38484 10.8542 7.35567 10.8776 7.3265 10.9009C7.2915 10.9242 7.2565 10.9417 7.2215 10.9534C7.1865 10.9709 7.1515 10.9826 7.11067 10.9884C7.07567 10.9942 7.03484 11.0001 6.99984 11.0001Z"
        fill="white"
      />
    </svg>
  );
};

export { Alarm };
