import { FC, useMemo, useRef, useState } from "react";
import {
  CarSliderBlock,
  CarSliderBody,
  CarSliderIndex,
  CarSliderPagination,
  CarSliderPaginationButton,
  CarSliderSlide,
  CarSliderSlideImage,
} from "./CarSliderStyles";
import { SwiperRef } from "swiper/react";
import Swiper from "swiper";

interface ICarSlider {
  images: string[];
}

const CarSlider: FC<ICarSlider> = ({ images }) => {
  const slider = useRef<SwiperRef | null>(null);

  const toSlide = (idx: number) => slider?.current?.swiper?.slideTo(idx);

  const [currentSlide, setCurrentSlide] = useState(0);

  const onSlideChange = (swiper: Swiper) => setCurrentSlide(swiper?.activeIndex || 0);

  return (
    <CarSliderBlock>
      <CarSliderBody ref={slider} slidesPerView={1} direction="horizontal" onSlideChange={onSlideChange} loop>
        {images.map((image) => {
          return (
            <CarSliderSlide key={image}>
              <CarSliderSlideImage src={image} />
            </CarSliderSlide>
          );
        })}
      </CarSliderBody>

      {images.length > 1 && (
        <CarSliderPagination>
          {images.map((image, idx) => {
            return (
              <CarSliderPaginationButton key={image} $active={currentSlide === idx} onClick={() => toSlide(idx)} />
            );
          })}
        </CarSliderPagination>
      )}

      <CarSliderIndex>
        {currentSlide + 1} / {images.length}
      </CarSliderIndex>
    </CarSliderBlock>
  );
};

export default CarSlider;
