import Header from "../../components/Header/Header";
import { Percent as PercentIcon } from "../../components/SVG/Percent";
import { FavouritesBlock, FavouritesList } from "./FavouritesStyles";
import { Car } from "../../components/Car/Car";
import Navigation from "../../components/Navigation/Navigation";
import { CarsService } from "../../services/carsService";
import { useEffect, useState } from "react";
import { ICar } from "../../types/car";
import { Loader } from "../../components/Modals/Loader/Loader";

const Favourites = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [cars, setCars] = useState<ICar[]>([]);

  const getData = async () => {
    const getCars = async () => {
      const cars = await CarsService.getFavourites();
      setCars(cars);
    };

    await Promise.all([getCars()]);

    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <FavouritesBlock>
      <Loader isOpen={isLoading} />

      <Header title="Избранное" subtitle="Список автомобилей, которые вы выбрали" icon={<PercentIcon />} />

      <FavouritesList>
        {cars.map((car) => {
          return <Car car={car} onChange={getData} key={car.vin} isDiscount />;
        })}
      </FavouritesList>

      <Navigation isFilter />
    </FavouritesBlock>
  );
};

export { Favourites };
