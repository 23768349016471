import styled from "styled-components";
import pxIntoRem from "../../../../utils/pxIntoRem";
import { Map } from "@pbe/react-yandex-maps";

const CarMapBlock = styled(Map)`
  margin-top: ${pxIntoRem(15)};
  margin-bottom: ${pxIntoRem(24)};
  height: ${pxIntoRem(250)};
  border-radius: ${pxIntoRem(12)};
  background: rgb(255, 255, 255);
  overflow: hidden;
`;

export { CarMapBlock };
