import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const CarBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${pxIntoRem(12)};
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;

const CarDiscountIcon = styled.img`
  width: ${pxIntoRem(40)};
  height: ${pxIntoRem(40)};
  position: absolute;
  top: ${pxIntoRem(7)};
  left: ${pxIntoRem(7)};
`;

interface ICarFavourite {
  $favourite: boolean;
}

const CarFavourite = styled.button<ICarFavourite>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(33.33)};
  height: ${pxIntoRem(33.33)};
  position: absolute;
  top: ${pxIntoRem(10.33)};
  right: ${pxIntoRem(10.33)};
  border-radius: 100%;
  cursor: pointer;
  background: ${({ $favourite }) => ($favourite ? "rgba(185, 11, 4, 0.4)" : "rgb(30, 30, 30, 0.4)")};
  transition-duration: 0.35s;

  svg {
    width: ${pxIntoRem(18)};

    path {
      transition-duration: 0.35s;
      fill: ${({ $favourite }) => ($favourite ? "#B90B04" : "#1E1E1E")};
    }
  }
`;

const CarImage = styled.img`
  height: ${pxIntoRem(220)};
  width: 100%;
  object-fit: cover;
`;

const CarContent = styled.div`
  background: rgb(255, 255, 255);
  padding: ${pxIntoRem(18)} ${pxIntoRem(14)};
`;

const CarTitle = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(18)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;

  span {
    color: rgb(185, 11, 4);
  }
`;

const CarText = styled.p`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 400;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(5)};

  span {
    font-weight: 500;
  }
`;

const CarInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxIntoRem(14)};
  margin-top: ${pxIntoRem(24)};
`;

const CarInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxIntoRem(10)};
`;

const CarInfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(40)};
  height: ${pxIntoRem(40)};
  background: rgb(244, 244, 244);
  border-radius: 100%;

  svg {
    width: ${pxIntoRem(24)};
    height: ${pxIntoRem(24)};
  }
`;

const CarInfoItemContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CarInfoTitle = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(10)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
`;

const CarInfoValue = styled.span`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(14)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  margin-top: ${pxIntoRem(3)};
`;

const CarLine = styled.div`
  margin-top: ${pxIntoRem(23.5)};
  height: ${pxIntoRem(1)};
  width: 100%;
  background-color: rgb(232, 232, 232);
`;

const CarPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${pxIntoRem(24.5)};
`;

const CarPriceValue = styled.h2`
  color: rgb(30, 30, 30);
  font-family: Montserrat;
  font-size: ${pxIntoRem(18)};
  font-weight: 700;
  line-height: ${pxIntoRem(22)};
  letter-spacing: 0%;
  text-align: left;
`;

const CarPriceCredit = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const CarPriceCreditTitle = styled.span`
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
  border-radius: ${pxIntoRem(30)};
  background-color: rgb(30, 30, 30);
  padding: ${pxIntoRem(5)} ${pxIntoRem(12)};
  height: ${pxIntoRem(25)};
  margin-right: ${pxIntoRem(10)};
`;

const CarPriceCreditValue = styled.span`
  color: rgb(86, 86, 86);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: ${pxIntoRem(15)};
  letter-spacing: 0%;
  text-align: left;
`;

const CarDiscount = styled.span`
  display: flex;
  color: rgb(185, 11, 4);
  font-family: Montserrat;
  font-size: ${pxIntoRem(12)};
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  text-decoration-line: line-through;
  margin-top: ${pxIntoRem(23.5)};

  & + ${CarPrice} {
    margin-top: ${pxIntoRem(3)};
  }
`;

export {
  CarBlock,
  CarImage,
  CarContent,
  CarInfo,
  CarInfoIcon,
  CarInfoItem,
  CarInfoItemContent,
  CarInfoTitle,
  CarInfoValue,
  CarText,
  CarTitle,
  CarLine,
  CarPrice,
  CarPriceCredit,
  CarPriceCreditTitle,
  CarPriceCreditValue,
  CarPriceValue,
  CarDiscount,
  CarDiscountIcon,
  CarFavourite,
};
