import { Placemark, YMaps } from "@pbe/react-yandex-maps";
import { FC, useMemo } from "react";
import { CarMapBlock } from "./CarMapStyles";

interface ICarMap {
  coordinates: string;
}

const CarMap: FC<ICarMap> = ({ coordinates }) => {
  const correctCoordinates = useMemo(() => {
    return coordinates.split(", ").map((value) => Number(value));
  }, [coordinates]);

  return (
    <YMaps>
      <CarMapBlock defaultState={{ center: correctCoordinates, zoom: 10 }}>
        <Placemark
          geometry={correctCoordinates}
          defaultOptions={{
            // preset: "islands#circleDotIcon",
            // Задаем цвет метки (в формате RGB).
            // iconColor: "#ffffff",
            // iconLayout: `<div style="width: 40px;height:40px;background:red;"></div>`,
            iconImageSize: [23, 23],
            iconLayout: "default#image",
            iconImageOffset: [0, 0],
            iconImageHref: "/images/point.png",
          }}
        />
      </CarMapBlock>
    </YMaps>
  );
};

export { CarMap };
