import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Preloader from "./components/Modals/Preloader/Preloader";
import { Brands } from "./pages/Brands/Brands";
import { Car } from "./pages/Car/Car";
import { Cars } from "./pages/Cars/Cars";
import { Favourites } from "./pages/Favourites/Favourites";
import { Index } from "./pages/Index/Index";
import { Suggestion } from "./pages/Suggestion/Suggestion";
import WebApp from "./utils/telegram/webapp";

const AppBlock = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    WebApp?.expand();
    WebApp?.enableClosingConfirmation();
    WebApp?.ready();
  }, []);

  return (
    <AppBlock>
      <Preloader isOpen={isLoading} />

      <Routes>
        <Route index element={<Index />} />
        <Route path="/brands/:region" element={<Brands />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/car/:vin" element={<Car />} />
        <Route path="/favourites" element={<Favourites />} />
        <Route path="/suggestion" element={<Suggestion />} />
      </Routes>
    </AppBlock>
  );
};

export default App;
