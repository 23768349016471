import { FC } from "react";
import { PreloaderBlock, PreloaderImage } from "./PreloaderStyles";
import Portal from "../../Portal/Portal";

interface IPreloader {
  isOpen: boolean;
}

const Preloader: FC<IPreloader> = ({ isOpen }) => {
  return (
    <Portal>
      <PreloaderBlock $open={isOpen}>
        <PreloaderImage alt="preloader" src="/images/preloader.png" />
      </PreloaderBlock>
    </Portal>
  );
};

export default Preloader;
